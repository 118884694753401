import { useQuery } from '@tanstack/react-query';
import { camelizeKeys } from 'humps';

import { useAxiosContext, useFeatureSetEnabled, useGlobalStore } from '~/hooks';
import { apiRoutes } from '~/routes';
import { AllAssetIds, SecurityAsset } from '~/types';

export const qkSecurity = 'qk:security';

const useGetSecurity = ({
  ticker = '',
  refetchInterval = false,
  enabled = true,
}: {
  ticker?: AllAssetIds;
  refetchInterval?: number | false;
  enabled?: boolean;
}) => {
  const { apiClient } = useAxiosContext();
  const [userAuthInfo] = useGlobalStore((state) => [state.userAuthInfo]);
  const disableRefetch = localStorage.getItem('enableRefetch') === 'false';
  const { isEnabled } = useFeatureSetEnabled();

  return useQuery({
    enabled: enabled && Boolean(userAuthInfo) && isEnabled(['securities']) && Boolean(ticker),
    queryKey: [qkSecurity, ticker, refetchInterval],
    refetchInterval: disableRefetch ? false : refetchInterval,
    queryFn: async () => {
      const response = await apiClient?.get(apiRoutes.redis.getSecurity({ ticker }));

      const formattedData: SecurityAsset = camelizeKeys(response?.data);

      return formattedData;
    },
  });
};

export default useGetSecurity;
