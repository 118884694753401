import { useQuery } from '@tanstack/react-query';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { FieldValues } from 'react-hook-form';

import { useAxiosContext, useGlobalStore } from '~/hooks';
import { apiRoutes } from '~/routes';
import { UserReport } from '~/types';
import { ignoreUpperCaseKeys } from '~/utils';

interface ReportFormData extends FieldValues {
  reportType?: string;
  reportProductType?: string;
  reportYear?: string;
}

export const qkUserReports = 'qk:userReports';

const useGetUserReports = ({ params }: { params?: ReportFormData }) => {
  const userAuthInfo = useGlobalStore((state) => state.userAuthInfo);
  const { apiClient } = useAxiosContext();

  return useQuery({
    enabled: Boolean(userAuthInfo),
    queryKey: [qkUserReports, params],
    queryFn: async () => {
      return apiClient
        ?.get(apiRoutes.reports(), {
          params: decamelizeKeys(params),
        })
        .then((r) => camelizeKeys(r.data, { process: ignoreUpperCaseKeys }) as UserReport[]);
    },
  });
};

export default useGetUserReports;
