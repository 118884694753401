import { useMutation } from '@tanstack/react-query';
import { Camelized, camelizeKeys, decamelizeKeys } from 'humps';

import { SecuritiesBuyOrderRequestBody } from '~/codegen/types';
import { useAxiosContext } from '~/hooks';
import { apiRoutes } from '~/routes';
import { Order } from '~/types';
import { ignoreUpperCaseKeys } from '~/utils';

const usePostSellSecurity = () => {
  const { apiClient } = useAxiosContext();

  return useMutation({
    mutationFn: async (body: Camelized<SecuritiesBuyOrderRequestBody>) => {
      return apiClient?.post(apiRoutes.sellSecurity(), decamelizeKeys(body)).then((r) => {
        const data = camelizeKeys(r.data, {
          process: ignoreUpperCaseKeys,
        }) as Order;

        return data;
      });
    },
  });
};

export default usePostSellSecurity;
