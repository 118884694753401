'use client';

import { Box, Flex, Grid, GridItem, Heading, Skeleton, Text } from '@chakra-ui/react';
import { dateFormats, formatUsd, IconMinus, IconMore, IconPlus, uiColors, utcDateToLocal } from '@cryptofi/core-ui';
import { Camelized } from 'humps';
import { range } from 'lodash';

import { tokenIdToName } from '~/constants';
import { useGetSearchTransactions, useGetSecurityFields } from '~/hooks';
import { AllAssetIds, TokenIdLookup } from '~/types';
import { isCrypto } from '~/utils';
import { RoboTransaction } from '~/utils/mockLatestTxs';

interface LatestTransactionsProps {
  roboTransactions?: Camelized<RoboTransaction>[];
}

const AssetLabel = ({ assetId }: { assetId: AllAssetIds }) => {
  const security = useGetSecurityFields({
    ticker: assetId,
    enabled: !isCrypto(assetId),
    fields: ['name', 'image'],
  });

  return (
    <Flex h="full" grow="1" flexDir="column" justifyContent="space-between">
      <Heading size="sm"> {assetId}</Heading>

      <Text color={uiColors.sonicSilver()} fontSize="xs" noOfLines={1}>
        {isCrypto(assetId) ? tokenIdToName[assetId as TokenIdLookup] : security.data?.name}
      </Text>
    </Flex>
  );
};

//todo: refactor this to use hook once robo tx API is ready
export default function LatestTransactions({ roboTransactions }: LatestTransactionsProps = {}) {
  const { data: transactions, isLoading, isSuccess, isError } = useGetSearchTransactions({ recordsPerPage: 4 });
  const transactionCount = roboTransactions?.length || transactions?.items?.length;

  const renderIcon = (transactionType: string) => {
    const sharedStyles = {
      borderRadius: '50%',
      padding: '4px',
      width: '1.3rem',
      height: '1.3rem',
    };

    if (transactionType === 'REBALANCE') {
      return (
        <IconMore
          __css={{
            path: { fill: 'rgba(240, 190, 0, 1)' },
            backgroundColor: 'rgba(255, 250, 221, 1)',
            ...sharedStyles,
          }}
        />
      );
    }
    if (transactionType === 'DEPOSIT') {
      return (
        <Box borderRadius="100" bgColor={uiColors.aijiroWhite()} p="1">
          <IconPlus h="4" w="4" __css={{ path: { fill: uiColors.mint() } }} />
        </Box>
      );
    }
    if (transactionType === 'WITHDRAWAL') {
      return (
        <Box borderRadius="100" bgColor={uiColors.redRemains()} p="1">
          <IconMinus h="3" w="3" __css={{ path: { fill: uiColors.heroicRed() } }} />
        </Box>
      );
    }
  };

  return (
    <>
      {roboTransactions ? (
        <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} templateRows="repeat(2, 1fr)">
          {roboTransactions.slice(0, 4).map((tx: RoboTransaction, index: number) => (
            <Flex
              key={tx.txId}
              justifyContent="space-between"
              py={{ base: '2', md: '4' }}
              px="6"
              borderRight={index % 2 === 0 ? '1px solid' : 'none'}
              borderBottom={index < 2 ? '1px solid' : 'none'}
              borderColor={{ base: 'transparent', md: 'gray.200' }}
            >
              <Flex alignItems="center" gap="2">
                {renderIcon(tx.transactionType)}

                <Text>
                  {tx.transactionType.charAt(0).toUpperCase().concat(tx.transactionType.slice(1).toLowerCase())}
                </Text>
              </Flex>

              <Flex flexDirection="column" alignItems="flex-end">
                <Text fontWeight="bold">${tx.transactionAmount}</Text>

                <Text>{utcDateToLocal({ dateFormat: dateFormats.dateOnly, timeString: tx.transactTime })}</Text>
              </Flex>
            </Flex>
          ))}
        </Grid>
      ) : (
        <Grid
          templateColumns={{
            base: '1fr',
            md: '1fr 1fr',
            xl: `repeat(${transactionCount || 4}, 1fr)`,
          }}
          w={transactionCount === 1 ? { xl: '50%' } : 'full'}
          borderRight={transactionCount === 1 ? { xl: `2px solid ${uiColors.lighthouse()}` } : 'none'}
          gridGap="2px"
          bgColor={!isLoading ? uiColors.lighthouse() : 'auto'}
        >
          {isLoading &&
            range(4).map((_, i) => (
              <GridItem key={i} h="4.75rem">
                <Skeleton h="full" />
              </GridItem>
            ))}

          {isSuccess && !transactionCount && (
            <GridItem colSpan={4} h="full" textAlign="center" bgColor={uiColors.white()}>
              You have not placed any trades
            </GridItem>
          )}

          {isSuccess &&
            transactions?.items?.map((tx) => (
              <GridItem key={tx.orderTxId} bgColor={uiColors.white()} p="4">
                <Flex h="full" gap="4" alignItems="center" justifyContent="space-between" w="full">
                  <Flex h="full" gap="4" alignItems="center">
                    {tx.transactionName === 'Buy' ? (
                      <Box borderRadius="100" bgColor={uiColors.aijiroWhite()} p="1">
                        <IconPlus h="4" w="4" __css={{ path: { fill: uiColors.mint() } }} />
                      </Box>
                    ) : (
                      <Box borderRadius="100" bgColor={uiColors.redRemains()} p="1">
                        <IconMinus h="3" w="3" __css={{ path: { fill: uiColors.heroicRed() } }} />
                      </Box>
                    )}

                    <AssetLabel assetId={tx.asset as AllAssetIds} />
                  </Flex>

                  <Flex h="full" grow="1" flexDir="column" alignItems="end" justifyContent="space-between">
                    <Heading size="xs">{formatUsd({ amount: tx.usdAmountGross || 0 })}</Heading>

                    <Text size="sm">
                      {utcDateToLocal({
                        dateFormat: dateFormats.dateOnly,
                        timeString: tx.transactTime,
                      })}
                    </Text>
                  </Flex>
                </Flex>
              </GridItem>
            ))}

          {(transactionCount === 3 || transactionCount === 1) && (
            <GridItem
              display={{ base: 'none', md: 'block', xl: 'none' }}
              h="full"
              w="full"
              bgColor={uiColors.white()}
            ></GridItem>
          )}

          {isError && (
            <GridItem h="full" textAlign="center" bgColor={uiColors.white()}>
              There was an error loading your transactions. Please try again later.
            </GridItem>
          )}
        </Grid>
      )}
    </>
  );
}
