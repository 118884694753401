'use client';
import { Flex, Heading, Text } from '@chakra-ui/react';
import {
  CfButtonGroup,
  CfButtonGroupButton,
  CfInput,
  IconClose,
  IconEnter,
  IconSearch,
  uiColors,
} from '@cryptofi/core-ui';
import { useRef, useState } from 'react';

import { useFeatureSetEnabled } from '~/hooks';
import { TokenIdLookup } from '~/types';

import AssetsSearchResults from './AssetsSearchResults';

export type SelectedFilter = 'all' | 'crypto' | 'securities';

export default function AssetsSearch({
  onClickCryptoAsset,
  onClickSecurity,
}: {
  onClickCryptoAsset: (assetId: TokenIdLookup) => void;
  onClickSecurity: (ticker: string) => void;
}) {
  const searchRef = useRef<HTMLInputElement>(null);
  const { isEnabled } = useFeatureSetEnabled();

  const getDefaultFilter = () => {
    if (isEnabled(['securities', 'crypto'])) {
      return 'all';
    }
    if (isEnabled(['securities'])) {
      return 'securities';
    }
    if (isEnabled(['crypto'])) {
      return 'crypto';
    }

    return 'all';
  };
  const [query, setQuery] = useState('');
  const [selectedFilter, setSelectedFilter] = useState<SelectedFilter>(getDefaultFilter());

  const borderStyles =
    selectedFilter !== 'all' ? { borderBottom: '1px solid', borderColor: uiColors.coolElegance() } : {};

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setQuery(e.currentTarget.value);
    }
  };

  const handleClickSearch = () => {
    if (searchRef.current) {
      setQuery(searchRef.current.value);
    }
  };

  const handleClearQuery = () => {
    setQuery('');
    setSelectedFilter('all');
    if (searchRef.current) {
      searchRef.current.value = '';
    }
  };

  const input = (
    <CfInput
      autoFocus
      ref={searchRef}
      onKeyDown={handleKeyDown}
      placeholder="Search"
      rightElement={
        query ? (
          <IconClose aria-label="Clear search" cursor="pointer" onClick={handleClearQuery} />
        ) : (
          <IconEnter aria-label="Submit search" cursor="pointer" onClick={handleClickSearch} />
        )
      }
      leftElement={<IconSearch />}
      name="query"
    />
  );

  if (query) {
    return (
      <Flex flexDir="column" width="100%">
        <Flex flexDir="column" gap={4} background="white" {...borderStyles}>
          {input}

          {isEnabled(['securities', 'crypto']) && (
            <CfButtonGroup>
              <CfButtonGroupButton
                isSelected={selectedFilter === 'all'}
                onClick={() => {
                  setSelectedFilter('all');
                }}
              >
                All assets
              </CfButtonGroupButton>

              <CfButtonGroupButton
                isSelected={selectedFilter === 'securities'}
                onClick={() => {
                  setSelectedFilter('securities');
                }}
              >
                Stocks
              </CfButtonGroupButton>

              <CfButtonGroupButton
                isSelected={selectedFilter === 'crypto'}
                onClick={() => {
                  setSelectedFilter('crypto');
                }}
              >
                Crypto
              </CfButtonGroupButton>
            </CfButtonGroup>
          )}

          <Heading py={6} size="sm">
            Showing results for &lsquo;{query}&lsquo;
          </Heading>
        </Flex>

        <AssetsSearchResults
          onClickCryptoAsset={onClickCryptoAsset}
          onClickSecurity={onClickSecurity}
          selectedFilter={selectedFilter}
          query={query}
        />
      </Flex>
    );
  }

  return (
    <Flex width="100%" flexDir="column" gap={4}>
      <Flex flexDir="column" gap={4} background="white">
        {input}
      </Flex>

      <Flex alignItems="center" justifyContent="center" height="full">
        <Flex flexDir="column" alignItems="center" color={uiColors.sonicSilver()} gap="4">
          <IconSearch __css={{ path: { fill: uiColors.coolElegance() } }} boxSize="8" />

          <Text>Search for your desired asset.</Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
