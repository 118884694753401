import { RiskProfile } from '~/types';

export const getExploreAssetsSecurities = (riskProfile: RiskProfile | undefined) => {
  // sets default list of securities based on a user's risk profile
  if (!riskProfile) {
    return [];
  }
  const exploreAssetsSecurities = {
    '-1': ['AAPL', 'NVDA', 'NFLX', 'MSFT', 'TSLA'],
    '1': ['XTWY', 'SHY', 'BWX', 'FTSD', 'SCHO'],
    '2': ['AAPL', 'NVDA', 'NFLX', 'MSFT', 'TSLA'],
    '3': ['AAPL', 'NVDA', 'NFLX', 'MSFT', 'TSLA'],
    '4': ['AAPL', 'NVDA', 'NFLX', 'MSFT', 'TSLA'],
  };
  return exploreAssetsSecurities[riskProfile];
};
