import { Box, Button, Divider, Flex, Heading, Text } from '@chakra-ui/react';
import { IconAlert, IconCheck, uiColors } from '@cryptofi/core-ui';

import { OnboardingStatus, Product } from '~/types';

import KycAlert from './KycAlert';

const KycResultsView = ({
  isApproved,
  onboardingError,
  onboardingStatusCrypto,
  onboardingStatusSecurities,
  isRejectedOrPending,
  products,
  onClose,
}: {
  isApproved: boolean;
  onboardingError: string | null;
  onboardingStatusCrypto?: OnboardingStatus;
  onboardingStatusSecurities?: OnboardingStatus;
  isRejectedOrPending: boolean;
  products: Product[];
  onClose: () => void;
}) => {
  return (
    <Box w="full">
      {(!isApproved || isRejectedOrPending) && (
        <Flex gap="4" flexDirection="column" alignItems="center" justifyContent="center" pb="4" width="full">
          {onboardingError && !isApproved && (
            <Box backgroundColor={uiColors.coolElegance()} borderRadius="full" p="4">
              <IconAlert
                w="6"
                h="6"
                __css={{
                  path: { fill: 'white' },
                }}
              />
            </Box>
          )}

          {onboardingError && <Text textAlign="center">{onboardingError}</Text>}

          {!onboardingError && (
            <Flex w="full" flexDirection="column" gap="4">
              {products.map((product) => (
                <KycAlert
                  key={product}
                  product={product}
                  onboardingStatus={product === 'crypto' ? onboardingStatusCrypto : onboardingStatusSecurities}
                />
              ))}
            </Flex>
          )}
        </Flex>
      )}

      {isRejectedOrPending && isApproved && <Divider mb="4" />}

      {isApproved && (
        <Flex gap="4" flexDirection="column" alignItems="center" justifyContent="center" pb="4">
          <Box backgroundColor="brand.500" borderRadius="full" p="4">
            <IconCheck
              __css={{
                path: { fill: 'white' },
              }}
            />
          </Box>

          <Heading as="h1" fontSize="xx-large" color="brand.500">
            You&apos;re all set!
          </Heading>

          <Text textAlign="center">Click below to get started investing directly from your bank account!</Text>

          <Button
            w="full"
            onClick={() => {
              onClose();
            }}
          >
            Start investing
          </Button>
        </Flex>
      )}
    </Box>
  );
};

export default KycResultsView;
