'use client';

import { Flex, FlexProps } from '@chakra-ui/react';
import { uiColors } from '@cryptofi/core-ui';

import NavItems from './NavItems';

const GlobalNav = ({ ...rest }: FlexProps) => {
  return (
    <Flex
      as="nav"
      position="sticky"
      zIndex="3" // lower than CfAreaChart tooltips.
      _hover={{
        zIndex: 5, // higher than PageLayout top nav and CfAreaChart.
      }}
      left="0"
      top="0"
      h="100vh"
      bg="white"
      flexDir="column"
      borderRight="solid 1px"
      borderColor={uiColors.coolElegance()}
      py="6"
      px="4"
      gap="3"
      {...rest}
    >
      <NavItems />
    </Flex>
  );
};

export default GlobalNav;
