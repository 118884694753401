import { dateFormats, utcDateToLocal } from '@cryptofi/core-ui';

import { ChartTimeRange } from '~/types';

/**
 * @description Modifies the date format based on the selected time range. For use with
 * the labelFormatter prop in Recharts components.
 */
const chartDateFormatter = ({ date, selectedTimeRange }: { date: string; selectedTimeRange: ChartTimeRange }) => {
  return utcDateToLocal({
    timeString: date,
    dateFormat:
      selectedTimeRange === 'allTime' || selectedTimeRange === 'pastYear'
        ? dateFormats.shortDateWithYear
        : dateFormats.shortDateWithTime,
  });
};

export default chartDateFormatter;
