'use client';

import { Box, Flex, Heading, IconButton, ModalProps, SlideFade } from '@chakra-ui/react';
import { CfModal, CfPrettyWrap, IconCaretLeft } from '@cryptofi/core-ui';
import { useCallback, useEffect, useState } from 'react';

import { useFeatureSetEnabled, useGetKycFields, useOnboardingInfo, usePostTelemetryEvent } from '~/hooks';
import { OnboardingModalViews, telemetryEvents } from '~/types';

import KycInfoView from './KycInfoView';
import KycResultsView from './KycResultsView';
import ProgressBar from './ProgressBar';
import SelectProductsView from './SelectProductsView';
import TermsAndConditionsView from './TermsAndConditionsView';
import WelcomeCryptoView from './WelcomeCryptoView';
import WelcomeSecuritiesView from './WelcomeSecuritiesView';

interface Props extends Omit<ModalProps, 'children'> {
  hasNewTerms?: boolean;
  onboardingInfo: ReturnType<typeof useOnboardingInfo>;
}

const OnboardingModal = ({ onClose, isOpen, hasNewTerms, onboardingInfo, ...rest }: Props) => {
  const {
    currentOnboardingStatus,
    onboardingStatusCrypto,
    onboardingStatusSecurities,
    isOnboarded,
    isOnboardedSecurities,
    isOnboardedCrypto,
    isRejectedOrPending,
    onboardingProducts,
    setOnboardingProducts,
    onboardingError,
    setOnboardingError,
    isRejectedOrPendingCrypto,
    isRejectedOrPendingSecurities,
    currentStep,
    totalSteps,
    getSectionProgress,
  } = onboardingInfo;
  const { isEnabled } = useFeatureSetEnabled();

  const [modalView, setModalView] = useState<OnboardingModalViews>('welcomeSecurities');

  const kycFields = useGetKycFields({ onboardingProducts });
  const resetOnboardingModal = useCallback(() => {
    setOnboardingError(null); // reset onboarding errors on close so user can retry kyc if they are still in notStarted status.
    setOnboardingProducts([]); // resets onboardingProducts that will be redetermined and set from useOnboardingInfo
    setModalView('welcomeSecurities'); // reset to default view
  }, [setOnboardingError, setOnboardingProducts, setModalView]);

  const { trackEvent } = usePostTelemetryEvent();
  const handleOnClose = useCallback(() => {
    const kycForm = document.getElementById('kycForm') as HTMLFormElement;
    if (kycForm) {
      const kycFields = Array.prototype.slice.call(kycForm.elements) as HTMLInputElement[];
      const capturedFields = Array.from(kycFields)
        .map((field) => {
          return field.value ? field.name : null;
        })
        .filter((fieldName) => fieldName !== null);

      trackEvent(telemetryEvents.KYCModalClosedKYCFormClient, { capturedFields });
    }

    onClose();
  }, [onClose, trackEvent]);

  // TODO refactor this
  // determines which view to show based on the current onboarding status/error and the user's selected products
  useEffect(() => {
    if (isOpen) {
      const handleSetModalView = (newModalView: OnboardingModalViews) => {
        // prevents accidental refreshing of modal views
        if (modalView !== newModalView) {
          setModalView(newModalView);
        }
      };
      if (hasNewTerms) {
        handleSetModalView('termsAndConditions');
      } else if (['approved', 'pending', 'rejected'].includes(currentOnboardingStatus || '') || onboardingError) {
        handleSetModalView('kycResults');
      } else if (modalView !== 'termsAndConditions' && currentOnboardingStatus === 'notAcceptedTandC') {
        if (isEnabled(['securities', 'crypto'])) {
          if (!isRejectedOrPending && !isOnboarded) {
            handleSetModalView('selectProducts');
          } else if (!isOnboardedSecurities && !isRejectedOrPendingSecurities) {
            handleSetModalView('welcomeSecurities');
          } else if (!isOnboardedCrypto && !isRejectedOrPendingCrypto) {
            handleSetModalView('welcomeCrypto');
          }
        } else if (isEnabled(['securities'])) {
          handleSetModalView('welcomeSecurities');
        } else if (isEnabled(['crypto'])) {
          handleSetModalView('welcomeCrypto');
        }
      } else if (currentOnboardingStatus === 'notStartedKyc') {
        handleSetModalView('kycInfo');
      }
    }
  }, [
    isOpen,
    setModalView,
    hasNewTerms,
    onboardingProducts,
    isOnboarded,
    isEnabled,
    isOnboardedSecurities,
    isOnboardedCrypto,
    modalView,
    currentOnboardingStatus,
    isRejectedOrPending,
    isRejectedOrPendingSecurities,
    isRejectedOrPendingCrypto,
    onboardingError,
  ]);

  const views = {
    welcomeSecurities: {
      showView: modalView === 'welcomeSecurities',
      bodyContent: <WelcomeSecuritiesView setModalView={setModalView} />,
      hasProgressBar: false,
      headerText: undefined,
    },
    welcomeCrypto: {
      showView: modalView === 'welcomeCrypto',
      bodyContent: <WelcomeCryptoView setModalView={setModalView} />,
      hasProgressBar: false,
      headerText: undefined,
    },
    selectProducts: {
      showView: modalView === 'selectProducts',
      bodyContent: (
        <SelectProductsView
          setOnboardingProducts={setOnboardingProducts}
          setModalView={setModalView}
          onboardingProducts={onboardingProducts}
        />
      ),
      hasProgressBar: false,
      headerText: undefined,
    },
    termsAndConditions: {
      showView: modalView === 'termsAndConditions',
      bodyContent: (
        <TermsAndConditionsView
          hasNewTerms={hasNewTerms}
          setOnboardingProducts={setOnboardingProducts}
          onboardingProducts={onboardingProducts}
          onClose={handleOnClose}
        />
      ),
      hasProgressBar: !hasNewTerms,
      headerText: hasNewTerms ? 'Updated Terms and Conditions' : 'Create account',
    },
    kycInfo: {
      showView: modalView === 'kycInfo',
      bodyContent: (
        <KycInfoView
          kycFields={kycFields}
          onboardingProducts={onboardingProducts}
          setOnboardingError={setOnboardingError}
          isOnboarded={currentOnboardingStatus === 'approved'}
        />
      ),
      hasProgressBar: true,
      headerText: 'Create account',
    },
    kycResults: {
      showView: modalView === 'kycResults',
      bodyContent: (
        <KycResultsView
          isApproved={currentOnboardingStatus === 'approved'}
          onboardingError={onboardingError}
          onboardingStatusCrypto={onboardingStatusCrypto}
          onboardingStatusSecurities={onboardingStatusSecurities}
          isRejectedOrPending={isRejectedOrPending}
          products={onboardingProducts.filter((product) => product === 'securities' || product === 'crypto')}
          onClose={handleOnClose}
        />
      ),
      hasProgressBar: false,
      headerText: isRejectedOrPending ? 'Account status' : undefined,
    },
  };

  const showBackButton =
    modalView === 'termsAndConditions' && isEnabled(['securities', 'crypto']) && isOnboarded === false && !hasNewTerms;

  return (
    <CfModal
      headerContent={
        <CfPrettyWrap>
          <Heading as="h1" size="md" textAlign="center" px="4">
            {showBackButton && (
              <IconButton
                icon={<IconCaretLeft __css={{ path: { fill: 'black !important' } }} />}
                position="absolute"
                left="3"
                top="2"
                variant="ghost"
                aria-label="Back to product selection"
                size="sm"
                onClick={() => {
                  setModalView('selectProducts');
                }}
                _hover={{ bg: 'blackAlpha.100' }}
                _focus={{ bg: 'blackAlpha.100' }}
              />
            )}

            {views[modalView].headerText}
          </Heading>
        </CfPrettyWrap>
      }
      onClose={handleOnClose}
      onCloseComplete={resetOnboardingModal}
      closeOnOverlayClick={modalView !== 'kycInfo'}
      closeOnEsc={!hasNewTerms}
      isDisabledCloseButton={hasNewTerms}
      isOpen={isOpen}
      size={modalView === 'kycResults' ? 'lg' : '4xl'}
      {...rest}
    >
      <Flex direction="column" px={{ md: '10' }}>
        <SlideFade in={views[modalView].hasProgressBar}>
          {views[modalView].hasProgressBar && (
            <Box mb="4">
              <ProgressBar
                currentStep={currentStep}
                totalSteps={totalSteps}
                isOnboarded={isOnboarded}
                getSectionProgress={getSectionProgress}
              />
            </Box>
          )}
        </SlideFade>

        {Object.entries(views).map(([key, { showView, bodyContent }]) => (
          <SlideFade in={showView} key={key}>
            {showView && bodyContent}
          </SlideFade>
        ))}
      </Flex>
    </CfModal>
  );
};

export default OnboardingModal;
