import { IconBookCheck, IconCircleCheck, IconDollarBill } from '@cryptofi/core-ui';

import { OnboardingCardContent } from '~/types';

export const introContentsCrypto: OnboardingCardContent[] = [
  {
    title: 'Invest with confidence',
    description: 'Invest in select assets using funds from your online bank account.',
    Icon: IconDollarBill,
    iconProps: {
      __css: {
        'path:nth-of-type(-n+1)': {
          fill: 'brand.500',
        },
      },
    },
  },
  {
    title: 'Easy and convenient',
    description:
      'Investing made easy. Simply choose the asset you want to trade, select the account to fund your purchases or receive your sale proceeds, and trade - no additional setup required.',
    Icon: IconCircleCheck,
    iconProps: {
      __css: {
        'path:nth-of-type(-n+1)': {
          fill: 'brand.500',
        },
      },
    },
  },
  {
    title: 'Expand your knowledge',
    description: 'Explore our curated knowledge section to enhance your investing prowess.',
    Icon: IconBookCheck,
    iconProps: {
      __css: {
        'path:nth-of-type(-n+2)': {
          fill: 'brand.500',
        },
      },
    },
  },
];

export const introContentsSecurities = [
  {
    description: 'Set up your account with ease right here. No linking to external websites.',
  },
  {
    description: 'Keep your investment portfolio under one account.',
  },
  {
    description: 'Explore robust educational content to expand your investing prowess with confidence.',
  },
  {
    description: 'No commissions on trades.',
  },
];
