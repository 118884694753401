'use client';

import { Link } from '@chakra-ui/next-js';
import { Flex, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { CfCard, CfSpinner, dateFormats, formatUsd, IconPieChart, utcDateToLocal } from '@cryptofi/core-ui';
import { UseQueryResult } from '@tanstack/react-query';
import { size } from 'lodash';

// eslint-disable-next-line no-restricted-imports
import { TransactionType } from '~/codegen/types';
import { AssetLogo, TransactionStatus } from '~/components';
import { uiRoutes } from '~/routes';
import { TransactionPaginator } from '~/types';

interface TransactionHistoryTableProps {
  transactions: UseQueryResult<TransactionPaginator | undefined, Error>;
}

const TransactionHistoryTable = ({ transactions }: TransactionHistoryTableProps) => {
  const { data, isLoading } = transactions;
  const hasData = Boolean(size(data?.items));

  if (isLoading) {
    return (
      <CfCard>
        <CfSpinner />
      </CfCard>
    );
  }

  return (
    <Flex direction="column" gap={4} w="full">
      <CfCard align="center">
        <TableContainer w="full">
          {hasData && (
            <Table variant="simple" fontSize="sm">
              <Thead>
                <Tr>
                  <Th py="3" px="4">
                    Asset
                  </Th>

                  <Th py="3" px="4" w="8rem" textAlign="right">
                    ID
                  </Th>

                  <Th py="3" px="4" textAlign="right">
                    Date
                  </Th>

                  <Th py="3" px="4" textAlign="center" width="8rem">
                    Type
                  </Th>

                  <Th py="3" px="4" textAlign="right">
                    Amount
                  </Th>

                  <Th py="3" px="4" textAlign="right" width="10rem">
                    Price
                  </Th>

                  <Th py="3" px="4" textAlign="right">
                    Cost
                  </Th>

                  <Th py="3" px="4" textAlign="right">
                    Fee
                  </Th>

                  <Th py="3" px="4" textAlign="center">
                    Status
                  </Th>
                </Tr>
              </Thead>

              {!hasData && (
                <Tbody>
                  <Tr>
                    <Td textAlign="center" colSpan={100}>
                      No transactions found
                    </Td>
                  </Tr>
                </Tbody>
              )}

              <Tbody>
                {data?.items?.map((transaction) => {
                  let transactionLabel;
                  const isDividend = transaction.transactionType === TransactionType.Dividend;
                  const isCrypto = transaction.assetType === 'CRYPTO';

                  if (isDividend) {
                    transactionLabel = (
                      <Flex alignItems="center" gap={4}>
                        {transaction.asset && <IconPieChart />}
                        USD
                      </Flex>
                    );
                  } else {
                    transactionLabel = (
                      <Link
                        display="inline-block"
                        href={
                          isCrypto
                            ? uiRoutes.assetDetailsCrypto(transaction.asset!)
                            : uiRoutes.assetDetailsSecurities(transaction.asset!)
                        }
                      >
                        <Flex alignItems="center" gap={4}>
                          {transaction.asset && (
                            <AssetLogo
                              assetId={transaction.asset}
                              assetType={transaction.assetType === 'CRYPTO' ? 'crypto' : 'securities'}
                            />
                          )}

                          {transaction.asset}
                        </Flex>
                      </Link>
                    );
                  }

                  return (
                    <Tr key={transaction.orderTxId}>
                      <Td py="3" px="4">
                        {transactionLabel}
                      </Td>

                      <Td py="3" px="4" fontFamily="mono" textAlign="right">
                        {transaction.orderTxId || '-'}
                      </Td>

                      <Td py="3" px="4" fontFamily="mono" textAlign="right">
                        {utcDateToLocal({ dateFormat: dateFormats.dateOnly, timeString: transaction.transactTime })}
                      </Td>

                      <Td py="3" px="4" textAlign="center">
                        {transaction.transactionName}
                      </Td>

                      <Td py="3" px="4" fontFamily="mono" textAlign="right">
                        {!isDividend && (transaction.assetAmount || 0).toFixed(isCrypto ? 8 : 5)}

                        {isDividend && formatUsd({ amount: transaction.usdAmountGross || 0 })}
                      </Td>

                      <Td py="3" px="4" fontFamily="mono" textAlign="right">
                        {(transaction.assetPrice &&
                          formatUsd({
                            amount: transaction.assetPrice,
                          })) ||
                          '-'}
                      </Td>

                      <Td py="3" px="4" fontFamily="mono" textAlign="right">
                        {formatUsd({
                          amount: transaction.usdAmountGross || 0,
                        })}
                      </Td>

                      <Td py="3" px="4" fontFamily="mono" textAlign="right">
                        {transaction.feeAmount ? formatUsd({ amount: Number(transaction.feeAmount) }) : '-'}
                      </Td>

                      <Td py="3" px="4" justifyItems="center">
                        <TransactionStatus transaction={transaction} />
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          )}
        </TableContainer>
      </CfCard>
    </Flex>
  );
};

export default TransactionHistoryTable;
