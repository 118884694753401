import { useMutation } from '@tanstack/react-query';
import { Camelized, decamelizeKeys } from 'humps';

import { RoboDepositRequestBody } from '~/codegen/types';
import { useAxiosContext } from '~/hooks';
import { apiRoutes } from '~/routes';

const usePostRoboDeposit = () => {
  const { apiClient } = useAxiosContext();

  return useMutation({
    mutationFn: async (body: Camelized<RoboDepositRequestBody>) => {
      return apiClient?.post(apiRoutes.roboDeposit(), decamelizeKeys(body));
    },
  });
};

export default usePostRoboDeposit;
