import { useContext } from 'react';
import { useStore } from 'zustand';
import { useShallow } from 'zustand/shallow';

import { GlobalStoreContext } from '~/components/Providers/StoreProvider';
import { GlobalStore } from '~/types';

const useGlobalStore = <T>(selector: (store: GlobalStore) => T): T => {
  const globalStoreContext = useContext(GlobalStoreContext);

  if (!globalStoreContext) {
    throw new Error(`useGlobalStore must be use within GlobalStoreProvider`);
  }

  return useStore(globalStoreContext, useShallow(selector));
};

export default useGlobalStore;
