import { Box, Button as ChakraButton, Flex, Portal, Text, Tooltip } from '@chakra-ui/react';

const ConsoleButton = ({
  hideButton,
  enableRefetch,
  onOpen,
  uiColor,
  hasSystemOverride,
  authBypassEnabled,
}: {
  hideButton: boolean;
  enableRefetch: boolean;
  onOpen: () => void;
  uiColor: string;
  hasSystemOverride: boolean;
  authBypassEnabled: boolean;
}) => {
  const showIndicator = !enableRefetch || hasSystemOverride || authBypassEnabled;

  return (
    <Portal>
      {!hideButton && (
        <Box
          position="fixed"
          top="4"
          right="4"
          zIndex="1000"
          bgColor="blackAlpha.800"
          borderRadius="md"
          transition="background-color 0.25s"
          className="dev-console"
          _hover={{ bgColor: 'blackAlpha.900' }}
        >
          {showIndicator && (
            <Box
              bg="purple.500"
              borderRadius="full"
              width="3"
              height="3"
              position="absolute"
              top="-0.25rem"
              right="-0.25rem"
              zIndex="1"
            />
          )}

          <Tooltip
            label={
              <Flex flexDir="column" gap="0.5" padding="1" fontFamily="mono" fontSize="12px">
                {authBypassEnabled && <Text>• Auth bypass enabled</Text>}

                {hasSystemOverride && <Text>• System override applied</Text>}

                {!enableRefetch && <Text>• Refetch intervals disabled</Text>}

                {!showIndicator && (
                  <>
                    <Text>• Open console (ctrl+d)</Text>

                    <Text>• Hide button (ctrl+j)</Text>
                  </>
                )}
              </Flex>
            }
            hasArrow
          >
            <ChakraButton
              aria-label="Open"
              size="sm"
              onClick={onOpen}
              fontSize="lg"
              bg="transparent !important"
              color={`${uiColor}.500`}
              padding="0"
              boxShadow="dark-lg"
            >
              ☆
            </ChakraButton>
          </Tooltip>
        </Box>
      )}
    </Portal>
  );
};

export default ConsoleButton;
