import { useMutation, useQueryClient } from '@tanstack/react-query';
import { camelizeKeys, decamelizeKeys } from 'humps';

import { qkUserAddress, useAxiosContext } from '~/hooks';
import { apiRoutes } from '~/routes';
import { UserAddress } from '~/types';
import { ignoreUpperCaseKeys } from '~/utils';

const usePostUserAddress = () => {
  const { apiClient } = useAxiosContext();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: UserAddress) => {
      return apiClient?.post(apiRoutes.userAddress(), decamelizeKeys(body)).then((r) => {
        return camelizeKeys(r.data, {
          process: ignoreUpperCaseKeys,
        });
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes(qkUserAddress),
      });
    },
  });
};

export default usePostUserAddress;
