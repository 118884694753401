import { useToast } from '@chakra-ui/react';
import { CfToast } from '@cryptofi/core-ui';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useRef } from 'react';

import { qkSearchTransactions, qkUser, useGetSearchTransactions, useGlobalStore } from '~/hooks';
import { OrderStatusLookup, orderStatusLookup } from '~/types';

const completedOrderStatuses = [
  orderStatusLookup.OK,
  orderStatusLookup.OK_PARTIAL,
  orderStatusLookup.PENDING_SELL_SETTLEMENT,
  orderStatusLookup.PENDING_BUY_SETTLEMENT,
  orderStatusLookup.PENDING_BUY_SETTLEMENT_SELL_UNAVAILABLE,
  orderStatusLookup.PENDING_BUY_SETTLEMENT_PARTIAL,
  orderStatusLookup.PENDING_SELL_SETTLEMENT_PARTIAL,
  orderStatusLookup.PENDING_XFR_SELL_SETTLED,
];
const failedOrderStatuses = [
  orderStatusLookup.OK_ERROR,
  orderStatusLookup.CANCELED,
  orderStatusLookup.ERROR,
  orderStatusLookup.ERROR_BUY_RECONCILED,
  orderStatusLookup.ERROR_BUY_OK_XFR,
  orderStatusLookup.ERROR_BUY_ERROR_XFR,
  orderStatusLookup.ERROR_SELL,
  orderStatusLookup.ERROR_XFR_OK_SELL,
];

// Order polling:
// 1. User submits an order
// 2. Order id and timestamp is saved in clientOrderIds array (local storage)
// 3. If there are any clientOrderIds stored, poll the orders endpoint when the app loads or continuously from when the order request was made..
// 4. If the order is returned by the BE and the status is one of the above completed statuses, it is removed from localstorage, and no polling is done

// User polling:
// 1. When an order is marked as completed, store the timestamp of when it was marked completed.
// 2. If the timestamp stored from the last completed order is less than a minute ago, poll the user endpoint.

const useBalanceAndOrdersPolling = () => {
  const ordersIntervalId = useRef<ReturnType<typeof setTimeout>>(undefined);
  const userIntervalId = useRef<ReturnType<typeof setTimeout>>(undefined);
  const toast = useToast();

  const renderOrderFailedToast = useCallback(
    () => (
      <CfToast
        title="Order failed"
        description="There was a problem processing your order, please contact support."
        status="error"
        isClosable
        onClose={() => {
          toast.closeAll();
        }}
      />
    ),
    [toast],
  );

  const [clientOrderIds, removeClientOrderId, setUserBalancePollingTimestamp, userBalancePollingTimestamp] =
    useGlobalStore(
      ({ clientOrderIds, removeClientOrderId, setUserBalancePollingTimestamp, userBalancePollingTimestamp }) => [
        clientOrderIds,
        removeClientOrderId,
        setUserBalancePollingTimestamp,
        userBalancePollingTimestamp,
      ],
    );
  const { data: transactions, isSuccess } = useGetSearchTransactions({
    params: { orderTxIds: clientOrderIds?.map((order) => order.id).join(',') },
    enabled: Boolean(clientOrderIds?.length),
  });
  const queryClient = useQueryClient();

  // In the case that an order was made more than 2 minutes ago, the client should forget about it.
  // This will guard against the case that an order was never completed and never removed from the clientOrderIds array.
  useEffect(() => {
    if (clientOrderIds) {
      // eslint-disable-next-line no-restricted-syntax
      const oneMinuteAgo = Date.now() - 60_000;
      const expiredOrderIds = clientOrderIds.filter((order) => order.timestamp <= oneMinuteAgo);

      expiredOrderIds.forEach((order) => {
        removeClientOrderId(order.id);
      });
    }
  }, [clientOrderIds, removeClientOrderId]);

  // If there are any ids in the clientOrderIds array that don't have a completed status in the transactions fetched
  // from the BE, transactions will be invalidated until it shows in a completed status and is removed from localstorage.
  useEffect(() => {
    const pollTransactions = () => {
      if (!clientOrderIds || clientOrderIds.length === 0) {
        if (ordersIntervalId.current) {
          clearTimeout(ordersIntervalId.current);
          ordersIntervalId.current = undefined;
        }
        return;
      }

      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey.includes(qkSearchTransactions);
        },
      });

      ordersIntervalId.current = setTimeout(pollTransactions, 5000);
    };

    if (!ordersIntervalId.current) {
      pollTransactions();
    }

    return () => {
      if (ordersIntervalId.current) {
        clearTimeout(ordersIntervalId.current);
        ordersIntervalId.current = undefined;
      }
    };
  }, [queryClient, clientOrderIds]);

  // This removes orders from the clientOrderIds array that have a completed status in the transactions fetched from the BE
  useEffect(() => {
    if (isSuccess && transactions?.items && clientOrderIds) {
      const completedOrders = transactions.items.filter(
        (transaction) =>
          transaction.orderTxId &&
          clientOrderIds.some(
            (entry) =>
              entry.id === transaction.orderTxId &&
              completedOrderStatuses.includes(transaction.status as OrderStatusLookup),
          ),
      );

      const failedOrders = transactions.items.filter(
        (transaction) =>
          transaction.orderTxId &&
          clientOrderIds.some(
            (entry) =>
              entry.id === transaction.orderTxId &&
              failedOrderStatuses.includes(transaction.status as OrderStatusLookup),
          ),
      );

      completedOrders.forEach((transaction) => {
        if (transaction.orderTxId) {
          removeClientOrderId(transaction.orderTxId);
          setUserBalancePollingTimestamp(Date.now());
        }
      });

      failedOrders.forEach((transaction) => {
        if (transaction.orderTxId) {
          toast({ render: renderOrderFailedToast, position: 'top-right' });
          removeClientOrderId(transaction.orderTxId);
        }
      });
    }
  }, [
    isSuccess,
    transactions?.items,
    clientOrderIds,
    removeClientOrderId,
    setUserBalancePollingTimestamp,
    toast,
    renderOrderFailedToast,
  ]);

  // When an order transitions to the completed status, there is a timestamp stored. If this timestamp is less than a
  // minute ago, the user's balance should be polled.
  useEffect(() => {
    const pollUserData = () => {
      const now = Date.now();
      // eslint-disable-next-line no-restricted-syntax
      const timeSinceLastOrderCompleted = now - (userBalancePollingTimestamp || 0);

      if (timeSinceLastOrderCompleted < 120_000) {
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey.includes(qkUser),
        });
        clearTimeout(userIntervalId.current);
        userIntervalId.current = setTimeout(pollUserData, 30_000);
      } else {
        if (userIntervalId.current) {
          clearTimeout(userIntervalId.current);
          userIntervalId.current = undefined;
          setUserBalancePollingTimestamp(null);
        }
      }
    };

    if (userBalancePollingTimestamp && !userIntervalId.current) {
      pollUserData();
    }

    return () => {
      if (userIntervalId.current) {
        clearTimeout(userIntervalId.current);
        userIntervalId.current = undefined;
      }
    };
  }, [queryClient, setUserBalancePollingTimestamp, userBalancePollingTimestamp]);
};

export default useBalanceAndOrdersPolling;
