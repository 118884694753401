import { useCallback } from 'react';

import { useGetFiInfo } from '~/hooks';
import { Product } from '~/types';

const useFeatureSetEnabled = () => {
  const fiInfo = useGetFiInfo();
  const enabledFeatureSet = fiInfo.data?.enabledFeatureSet;

  /**
   * @description Checks if ALL the specified feature sets are enabled.
   *
   * @param {Product[]} featureSet - An array of features to check
   * @return {boolean} The overall result of whether all features in the set are enabled
   */
  const isEnabled = useCallback(
    (featureSet: Product[]) => {
      return featureSet.every((feature) => {
        switch (feature) {
          case 'securities':
            return enabledFeatureSet?.isSecuritiesEnabled;
          case 'crypto':
            return enabledFeatureSet?.isCryptoEnabled;
          case 'robo':
            return enabledFeatureSet?.isRoboEnabled;
        }
      });
    },
    [enabledFeatureSet?.isCryptoEnabled, enabledFeatureSet?.isSecuritiesEnabled, enabledFeatureSet?.isRoboEnabled],
  );

  return { isEnabled };
};

export default useFeatureSetEnabled;
