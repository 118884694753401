'use client';

import { Link } from '@chakra-ui/next-js';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Flex,
  ResponsiveValue,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react';
import { dateFormats, formatUsd, IconCaretDown, IconInfo, uiColors } from '@cryptofi/core-ui';
import dayjs from 'dayjs';

import { AssetLogo, LabelTd, TransactionStatus, ValueTd } from '~/components';
import { OrderStatusKeys, orderStatusLookup } from '~/constants';
import { uiRoutes } from '~/routes';
import { Transaction } from '~/types';

type TransactionAccordionProps = {
  transaction: Transaction;
  initiallyCollapsed?: boolean;
  variant?: ResponsiveValue<string> | undefined;
  allowToggle?: boolean;
};

const TransactionAccordion = ({
  transaction,
  initiallyCollapsed = false,
  variant,
  allowToggle = true,
}: TransactionAccordionProps) => {
  const isPending = orderStatusLookup[transaction.status as OrderStatusKeys] === 'Pending';
  const isCrypto = transaction.assetType === 'CRYPTO';

  const hasValidAmount = transaction.assetAmount && transaction.assetAmount > 0;
  const decimalPlaces = isCrypto ? 8 : 5;
  const formattedAmount = hasValidAmount ? transaction.assetAmount?.toFixed(decimalPlaces) : 0;
  const isSellSecurity = transaction.transactionName === 'Sell' && !isCrypto;
  return (
    <Accordion
      w="full"
      defaultIndex={initiallyCollapsed ? undefined : [0]}
      allowToggle={allowToggle}
      variant={variant || ''}
    >
      <AccordionItem>
        {({ isExpanded }) => (
          <>
            <AccordionButton
              cursor={allowToggle ? 'pointer' : 'auto'}
              _hover={allowToggle ? { bg: uiColors.lighthouse() } : { bg: uiColors.white() }}
            >
              <Flex w="full" justifyContent="space-between" alignItems="center">
                <Link
                  href={
                    isCrypto
                      ? uiRoutes.assetDetailsCrypto(transaction.asset!)
                      : uiRoutes.assetDetailsSecurities(transaction.asset!)
                  }
                >
                  <AssetLogo assetId={transaction?.asset || ''} assetType={isCrypto ? 'crypto' : 'securities'} showId />
                </Link>

                <Flex direction="column">
                  <Text fontFamily="heading" textAlign="right">
                    {formattedAmount}
                  </Text>

                  <Text textAlign="right">
                    {dayjs.utc(transaction?.transactTime).local().format(dateFormats.dateOnly)}
                  </Text>
                </Flex>
              </Flex>

              {allowToggle && (
                <IconCaretDown
                  ml="4"
                  transform={isExpanded ? 'rotate(-180deg)' : 'rotate(0deg)'}
                  transition="transform 0.2s ease-in-out"
                  h="1rem"
                  w="1rem"
                  __css={{ path: { fill: uiColors.sonicSilver() } }}
                />
              )}
            </AccordionButton>

            <AccordionPanel background={uiColors.lighthouse()}>
              <Table variant="unstyled">
                <Tbody>
                  <Tr fontFamily="heading" fontSize="md">
                    <Td pl="0" py="2.5">
                      Status
                    </Td>

                    <Td pr="0" py="2.5" textAlign="right">
                      <TransactionStatus transaction={transaction} justifyContent="flex-end" />
                    </Td>
                  </Tr>

                  <Tr>
                    <Td colSpan={2} p="0">
                      {isSellSecurity && transaction.status === 'PENDING_SELL_SETTLEMENT' && (
                        <Flex mb="2" alignItems="top">
                          <IconInfo
                            mr="2"
                            mt="0.5"
                            boxSize="4"
                            __css={{
                              path: { fill: uiColors.sonicSilver() },
                            }}
                          />

                          <Text
                            display={{ base: 'block', md: 'none' }}
                            width="full"
                            fontSize="xs"
                            color={uiColors.sonicSilver()}
                          >
                            Proceeds from sales are processed on the following business day.
                          </Text>
                        </Flex>
                      )}
                    </Td>
                  </Tr>

                  <Tr>
                    <LabelTd>Type</LabelTd>

                    <ValueTd>{transaction.transactionName}</ValueTd>
                  </Tr>

                  <Tr>
                    <LabelTd>Asset price</LabelTd>

                    <ValueTd>
                      {isPending ? <Skeleton>$0.00</Skeleton> : formatUsd({ amount: transaction.assetPrice || 0 })}
                    </ValueTd>
                  </Tr>

                  <Tr>
                    <LabelTd>Amount</LabelTd>

                    <ValueTd>{formatUsd({ amount: transaction.usdAmountGross || 0 })}</ValueTd>
                  </Tr>

                  <Tr>
                    <LabelTd>Transaction fee</LabelTd>

                    <ValueTd>{formatUsd({ amount: transaction.feeAmount || 0 })}</ValueTd>
                  </Tr>
                </Tbody>
              </Table>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};

export default TransactionAccordion;
