import Big from 'big.js';
import { size } from 'lodash';
import { useMemo, useState } from 'react';

import { useFeatureSetEnabled, useGetSecurities, useGetTokenPrices, useGetUser } from '~/hooks';
import { InvestmentGroup } from '~/types';
import {
  calculateCryptoInvestments,
  calculateGainLoss,
  calculateSecurityInvestments,
  calculateTotalBalance,
} from '~/utils/investments';

const useUserInvestments = () => {
  const { isEnabled } = useFeatureSetEnabled();
  const user = useGetUser();
  const { securitiesSelfDirectedAccount: selfDirectedAccount, roboAccounts } = user.data || {};

  const securityTickers = Object.keys(selfDirectedAccount?.balance || {}).join(',');
  const roboTickers = Object.keys(roboAccounts?.[0]?.balance || {}).join(',');
  const securityPrices = useGetSecurities({ tickers: securityTickers, refetchInterval: 60_000 });
  const roboSecurityPrices = useGetSecurities({ tickers: roboTickers, refetchInterval: 60_000 });
  const cryptoPrices = useGetTokenPrices({ refetchInterval: 60_000 });

  const [isCalculating, setIsCalculating] = useState({
    securities: false,
    crypto: false,
    robo: false,
  });

  const securityInvestments: InvestmentGroup = useMemo(() => {
    if (securityPrices.data && selfDirectedAccount?.balance) {
      setIsCalculating((prev) => ({ ...prev, securities: true }));
      const assets = calculateSecurityInvestments({
        securityPrices: securityPrices.data,
        userSecurities: selfDirectedAccount?.balance,
      });
      const totalAmountUsd = calculateTotalBalance(assets);
      const gainLossAmount = calculateGainLoss(assets);
      setIsCalculating((prev) => ({ ...prev, securities: false }));
      return {
        assets,
        totalAmountUsd,
        gainLossAmount,
        size: size(assets),
      };
    }
    return { assets: [], totalAmountUsd: 0, gainLossAmount: { amount: 0, percent: 0 }, size: 0 };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityPrices.data, selfDirectedAccount?.balance]);

  const cryptoInvestments: InvestmentGroup = useMemo(() => {
    setIsCalculating((prev) => ({ ...prev, crypto: true }));
    const assets = calculateCryptoInvestments(cryptoPrices.data || []);
    const totalAmountUsd = calculateTotalBalance(assets);
    const gainLossAmount = calculateGainLoss(assets);
    setIsCalculating((prev) => ({ ...prev, crypto: false }));
    return {
      assets,
      totalAmountUsd,
      gainLossAmount,
      size: size(assets),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cryptoPrices.data]);

  const roboInvestments: InvestmentGroup = useMemo(() => {
    if (roboSecurityPrices.data && roboAccounts?.[0]?.balance) {
      setIsCalculating((prev) => ({ ...prev, robo: true }));
      const assets = calculateSecurityInvestments({
        securityPrices: roboSecurityPrices.data,
        userSecurities: roboAccounts?.[0]?.balance,
      });
      const totalAmountUsd = calculateTotalBalance(assets);
      const gainLossAmount = calculateGainLoss(assets);
      setIsCalculating((prev) => ({ ...prev, robo: false }));
      return {
        assets,
        totalAmountUsd,
        gainLossAmount,
        size: size(assets),
      };
    }
    return { assets: [], totalAmountUsd: 0, gainLossAmount: { amount: 0, percent: 0 }, size: 0 };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roboSecurityPrices.data, roboAccounts?.[0]?.balance]);

  const allInvestments: InvestmentGroup = useMemo(() => {
    return {
      assets: [...securityInvestments.assets, ...cryptoInvestments.assets],
      totalAmountUsd: Big(cryptoInvestments.totalAmountUsd).plus(securityInvestments.totalAmountUsd).toNumber(),
      gainLossAmount: {
        amount: Big(cryptoInvestments.gainLossAmount.amount).plus(securityInvestments.gainLossAmount.amount).toNumber(),
        percent: Big(cryptoInvestments.gainLossAmount.percent)
          .plus(securityInvestments.gainLossAmount.percent)
          .toNumber(),
      },
      size: Big(securityInvestments.size).plus(cryptoInvestments.size).toNumber(),
    };
  }, [securityInvestments, cryptoInvestments]);

  const isSuccessRobo = isEnabled(['robo']) ? roboSecurityPrices.isSuccess : true;
  const isSuccessSecurities = isEnabled(['securities']) ? securityPrices.isSuccess : true;
  const isSuccessCrypto = isEnabled(['crypto']) ? cryptoPrices.isSuccess : true;
  const isLoadingSecurities = isEnabled(['securities']) ? securityPrices.isLoading : false;
  const isLoadingCrypto = isEnabled(['crypto']) ? cryptoPrices.isLoading : false;
  return {
    isSuccess: isSuccessSecurities || isSuccessCrypto || isSuccessRobo || !securityTickers || !roboTickers,
    isLoading:
      isLoadingSecurities || isLoadingCrypto || isCalculating.securities || isCalculating.crypto || isCalculating.robo,
    isError: securityPrices.isError || cryptoPrices.isError,
    data: {
      securityInvestments,
      roboInvestments,
      cryptoInvestments,
      allInvestments,
    },
  };
};

export default useUserInvestments;
