import { Center, Text } from '@chakra-ui/react';
import { CfBreakpointDebugger, CfSpinner, layoutMinWidth } from '@cryptofi/core-ui';
import { isEmpty } from 'lodash';
import { useState } from 'react';

import { DevConsole, DevOnly } from '~/components';
import {
  useBalanceAndOrdersPolling,
  useGetFiInfo,
  useGetUser,
  useGetUserBankAccounts,
  useLoadingMessages,
} from '~/hooks';

const DataProvider = ({ children }: { children: React.ReactElement<any> }) => {
  // these are required before rendering the app, due to conditional views based on user onboarding state and FI feature set
  const blockingRequests = [useGetUser(), useGetFiInfo()];

  useGetUserBankAccounts(); // fetched early because accounts are slow to load from the banking core, used in the invest modal
  useBalanceAndOrdersPolling();

  const [showBreakpointDebugger, setShowBreakpointDebugger] = useState(false);

  const { loadingMessages } = useLoadingMessages({
    messages: ['Getting your account balances...', 'Getting latest prices...', 'Just a few more seconds...'],
  });

  // TODO: add error logging CORE-844
  // https://github.com/vercel/next.js/issues/55462
  if (blockingRequests.some((request) => request.isError)) {
    return (
      <Center minHeight="100vh" flexDirection="column" gap="4" textAlign="center" minW={layoutMinWidth} p="6">
        <Text>Something went wrong. Please try again later.</Text>
      </Center>
    );
  }

  if (blockingRequests.some((request) => isEmpty(request.data))) {
    return (
      <Center minHeight="100vh" flexDirection="column" gap="4" textAlign="center" minW={layoutMinWidth} p="6">
        <Text>{loadingMessages}</Text>

        <CfSpinner margin={5} boxSize="12" />
      </Center>
    );
  }

  return (
    <>
      {children}

      <DevOnly>
        {!showBreakpointDebugger && <DevConsole setShowBreakpointDebugger={setShowBreakpointDebugger} />}

        {showBreakpointDebugger && (
          <CfBreakpointDebugger
            isCloseable
            onClick={() => {
              setShowBreakpointDebugger(false);
            }}
          />
        )}
      </DevOnly>
    </>
  );
};

export { DataProvider };
