'use client';

import { Flex, Heading, Text } from '@chakra-ui/react';
import { CfCarousel, uiColors } from '@cryptofi/core-ui';

import { introContentsCrypto } from '~/constants';
import { OnboardingCardContent } from '~/types';

const CarouselContent = ({
  content,
  stepNumber,
  totalSteps,
}: {
  content: OnboardingCardContent;
  stepNumber: number;
  totalSteps: number;
}) => {
  const { Icon, title, description, iconProps } = content;

  return (
    <Flex direction={{ base: 'column', md: 'row' }} gap={4}>
      <Flex alignItems="center" justifyContent="center" minWidth="6rem" minHeight="3rem">
        <Icon boxSize={20} {...iconProps} />
      </Flex>

      <Flex
        direction="column"
        gap={2}
        justifyContent="center"
        textAlign={{ base: 'center', md: 'left' }}
        minHeight="8rem"
      >
        <Text fontSize="sm" fontFamily="heading" color={uiColors.coolElegance()}>
          {`${stepNumber}/${totalSteps}`}
        </Text>

        <Heading as="h3" color="brand.500" fontSize="lg">
          {title}
        </Heading>

        <Text
          fontSize="sm"
          lineHeight="normal"
          margin={{
            base: '0 auto',
            md: '0',
          }}
        >
          {description}
        </Text>
      </Flex>
    </Flex>
  );
};

const IntroductionCard = () => {
  const carouselContents = introContentsCrypto.map((content, i) => (
    // eslint-disable-next-line no-restricted-syntax
    <CarouselContent stepNumber={i + 1} totalSteps={introContentsCrypto.length} key={content.title} content={content} />
  ));

  return <CfCarousel carouselContents={carouselContents} justifyContent="space-between" />;
};

export default IntroductionCard;
