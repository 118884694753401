'use client';

import { Flex, FlexProps, Tooltip } from '@chakra-ui/react';
import { CfTag, IconInfo, uiColors } from '@cryptofi/core-ui';

import { OrderStatusKeys, orderStatusLookup } from '~/constants';
import { Transaction } from '~/types';
import { isCrypto } from '~/utils';

interface Props extends FlexProps {
  transaction: Transaction;
}

const TransactionStatus = ({ transaction, ...rest }: Props) => {
  const isSellSecurity = transaction.transactionName === 'Sell' && !isCrypto(transaction.asset);
  const tooltip = isSellSecurity ? 'Proceeds from sales are processed on the following business day.' : '';
  const label = orderStatusLookup[transaction.status as OrderStatusKeys];

  return (
    <Flex direction="column" gap={1}>
      <Tooltip hasArrow label={tooltip}>
        <Flex alignItems="center" {...rest}>
          <CfTag label={label} fontSize="xs" />

          {isSellSecurity && transaction.status === 'PENDING_SELL_SETTLEMENT' && (
            <IconInfo
              display={{ base: 'none', md: 'block' }}
              ml="2"
              boxSize="4"
              __css={{
                path: { fill: uiColors.sonicSilver() },
              }}
            />
          )}
        </Flex>
      </Tooltip>
    </Flex>
  );
};

export default TransactionStatus;
