import { MenuItem as ChakraMenuItem } from '@chakra-ui/react';

import { KycStatusLookup } from '~/types';

const MenuItem = ({ kycState, onClick }: { kycState: KycStatusLookup; onClick: () => void }) => {
  return (
    <ChakraMenuItem
      bg="transparent"
      _hover={{
        bg: 'whiteAlpha.200',
      }}
      _focus={{
        bg: 'whiteAlpha.200',
      }}
      onClick={onClick}
    >
      {kycState}
    </ChakraMenuItem>
  );
};

export default MenuItem;
