import { useQuery } from '@tanstack/react-query';
import { camelizeKeys, Decamelized } from 'humps';

import { useAxiosContext, useFeatureSetEnabled, useGlobalStore } from '~/hooks';
import { apiRoutes } from '~/routes';
import { SecurityAsset } from '~/types';

export const qkSecurities = 'qk:securities';

const useGetSecurities = ({
  tickers,
  refetchInterval = false,
}: {
  tickers: string;
  refetchInterval?: number | false;
}) => {
  const { apiClient } = useAxiosContext();
  const [userAuthInfo] = useGlobalStore((state) => [state.userAuthInfo]);
  const disableRefetch = localStorage.getItem('enableRefetch') === 'false';
  const { isEnabled } = useFeatureSetEnabled();

  return useQuery({
    refetchInterval: disableRefetch ? false : refetchInterval,
    enabled: Boolean(userAuthInfo) && isEnabled(['securities']) && Boolean(tickers),
    queryKey: [qkSecurities, tickers, refetchInterval],
    queryFn: async () => {
      const response = await apiClient?.get(apiRoutes.redis.getSecurities({ tickers }));

      const formattedData: SecurityAsset[] = response?.data.map((value: Decamelized<SecurityAsset>) => {
        return camelizeKeys(value);
      });

      return formattedData;
    },
  });
};

export default useGetSecurities;
