import { useQuery } from '@tanstack/react-query';
import { camelizeKeys } from 'humps';

import { useAxiosContext, useGlobalStore } from '~/hooks';
import { apiRoutes } from '~/routes';
import { TokenHistoricalPriceV2 } from '~/types';
import { ignoreUpperCaseKeys } from '~/utils';

export const qkTokenPriceHistory = 'qk:tokenPriceHistory';

const useGetTokenPriceHistory = () => {
  const userAuthInfo = useGlobalStore((state) => state.userAuthInfo);
  const { apiClient } = useAxiosContext();

  return useQuery({
    enabled: Boolean(userAuthInfo),
    queryKey: [qkTokenPriceHistory],
    queryFn: async () => {
      return apiClient?.get(apiRoutes.priceHistory()).then((r) => {
        return camelizeKeys(r.data, { process: ignoreUpperCaseKeys }) as TokenHistoricalPriceV2;
      });
    },
  });
};

export default useGetTokenPriceHistory;
