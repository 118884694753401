import axios from 'axios';
import { Decamelized } from 'humps';

import { apiRoutes } from '~/routes';
import { AuthResponse } from '~/types';
import { logError } from '~/utils';

const useAxios = () => {
  const axiosInstance = axios.create();

  axiosInstance.interceptors.request.use(
    (config) => {
      if (config.params) {
        // Remove blank values from request query params
        const blankValues = [undefined, null, ''];
        Object.keys(config.params).forEach((key) => {
          if (blankValues.includes(config.params[key])) {
            delete config.params[key];
          }
        });
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  axiosInstance.interceptors.response.use(
    (config) => config,
    async (error) => {
      if (error.response?.status === 401) {
        const { data }: { data: Decamelized<AuthResponse> } = await axiosInstance.get(apiRoutes.auth());
        if (data.redirect_url) {
          const redirectUrl = new URL(data.redirect_url);
          window.location.replace(redirectUrl.toString());
        }
      } else if (error.response?.status >= 500) {
        logError({ error });
      }
      return Promise.reject(error);
    },
  );

  return axiosInstance;
};

export default useAxios;
