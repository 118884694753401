import { useQuery } from '@tanstack/react-query';
import { camelizeKeys } from 'humps';

import { useAxiosContext, useFeatureSetEnabled, useGlobalStore } from '~/hooks';
import { apiRoutes } from '~/routes';
import { SecurityAsset } from '~/types';

export const qkSecurityFields = 'qk:securityFields';

const useGetSecurityFields = ({
  fields,
  ticker,
  enabled = true,
}: {
  fields: (keyof SecurityAsset)[];
  ticker: string;
  enabled?: boolean;
}) => {
  const { apiClient } = useAxiosContext();
  const [userAuthInfo] = useGlobalStore((state) => [state.userAuthInfo]);
  const { isEnabled } = useFeatureSetEnabled();

  return useQuery({
    enabled: enabled && Boolean(userAuthInfo) && isEnabled(['securities']) && Boolean(ticker),
    staleTime: Infinity, // image and name are relatively static, thus we can cache them indefinitely
    queryKey: [qkSecurityFields, ticker, fields],
    queryFn: async () => {
      const response = await apiClient?.get(apiRoutes.redis.getSecurityFields({ ticker }), {
        params: {
          fields,
        },
      });

      const formattedData: SecurityAsset = camelizeKeys(response?.data);

      return formattedData;
    },
  });
};

export default useGetSecurityFields;
