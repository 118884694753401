import { Button, Flex, Text } from '@chakra-ui/react';
import { CfButtonGroup, CfButtonGroupButton } from '@cryptofi/core-ui';
import { useFormContext } from 'react-hook-form';

import { AssetUnavailable, KycAlert } from '~/components';
import {
  useCanUserPurchaseSecurity,
  useGetSystemStatus,
  useGlobalModalContext,
  useInvestModalContext,
  useOnboardingInfo,
  usePostTelemetryEvent,
  useSystemDownByFeature,
} from '~/hooks';
import { telemetryEvents, TransactionTypes } from '~/types';
import { isFalse } from '~/utils';

import BuyForm from './BuyForm';
import SellForm from './SellForm';
import { isFormValid } from './utils';

const StartTransaction = ({ isLoading }: { isLoading: boolean }) => {
  const { transactionType } = useInvestModalContext();

  return (
    <Flex py="4" width="100%">
      {transactionType === 'buy' && <BuyForm isLoading={isLoading} />}

      {transactionType !== 'buy' && <SellForm isLoading={isLoading} />}
    </Flex>
  );
};
export default StartTransaction;

// external sub components

const HeaderContent = () => {
  const { transactionType, setTransactionType, selectedAsset, selectedSecurity } = useInvestModalContext();
  const { clearErrors, setValue } = useFormContext();
  const { trackEvent } = usePostTelemetryEvent();
  const systemStatus = useGetSystemStatus();

  const canUserPurchaseSecurity = useCanUserPurchaseSecurity(selectedSecurity?.data);

  const handleOnClick = (option: TransactionTypes) => {
    setTransactionType(option);
    setValue('transactionType', option);
    // clear any errors when switching forms so that the preview transaction button does not get blocked by an error from the non selected form
    clearErrors();

    if (option === 'buy') {
      trackEvent(telemetryEvents.ClickedAssetBuyButtonClient, { asset: selectedAsset?.id });
    } else if (option === 'sell') {
      trackEvent(telemetryEvents.ClickedAssetSellButtonClient, { asset: selectedAsset?.id });
    }
  };

  const buttonProps = (option: TransactionTypes) => {
    return {
      onClick: () => handleOnClick(option),
      isSelected: transactionType === option,
      minWidth: '16',
      paddingX: '4',
    };
  };

  const assetUnavailable = () => {
    const isCrypto = selectedAsset?.type === 'crypto';
    const isUnavailableRiskTolerance = !canUserPurchaseSecurity && !isCrypto;
    const restProps = {
      mt: '10',
    };

    if (!isCrypto && isFalse(systemStatus.data?.isSecuritiesAvailable)) {
      return <AssetUnavailable reason="systemDownSecurities" {...restProps} />;
    }

    if (isCrypto && isFalse(systemStatus.data?.isCryptoAvailable)) {
      return <AssetUnavailable reason="systemDownCrypto" {...restProps} />;
    }

    if (!isCrypto && isFalse(systemStatus.data?.securitiesTradingOpen)) {
      return <AssetUnavailable reason="marketClosed" {...restProps} />;
    }

    if (isUnavailableRiskTolerance && transactionType === 'buy') {
      return <AssetUnavailable reason="riskTolerance" {...restProps} />;
    }
  };

  return (
    <>
      <Flex justifyContent="center" mt="6">
        <CfButtonGroup>
          <CfButtonGroupButton {...buttonProps('buy')}>Buy</CfButtonGroupButton>

          <CfButtonGroupButton {...buttonProps('sell')}>Sell</CfButtonGroupButton>
        </CfButtonGroup>
      </Flex>

      {assetUnavailable()}
    </>
  );
};
StartTransaction.HeaderContent = HeaderContent;

const FooterContent = () => {
  const { getFieldState, getValues } = useFormContext();
  const { transactionType, selectedAsset, selectedSecurity } = useInvestModalContext();
  const { trackEvent } = usePostTelemetryEvent();
  const { isSystemDownByFeature } = useSystemDownByFeature();
  const systemStatus = useGetSystemStatus();
  const { resumeOnboarding } = useGlobalModalContext();
  const {
    isOnboardedSecurities,
    isOnboardedCrypto,
    isRejectedOrPending,
    isRejectedOrPendingCrypto,
    isRejectedOrPendingSecurities,
    onboardingStatusCrypto,
    onboardingStatusSecurities,
  } = useOnboardingInfo();
  const isAssetCrypto = selectedAsset?.type === 'crypto';

  const handlePreviewTransactionClick = () => {
    const eventType =
      transactionType === 'buy'
        ? telemetryEvents.ClickedAssetPreviewBuyButtonClient
        : telemetryEvents.ClickedAssetPreviewSellButtonClient;

    trackEvent(eventType, { asset: selectedAsset?.id });
  };

  const canUserPurchaseSecurity = useCanUserPurchaseSecurity(selectedSecurity?.data);
  // Disable the preview button if it is a buy transaction for a security and the user's risk profile is too low
  const isUnavailableRiskTolerance = !canUserPurchaseSecurity && transactionType === 'buy' && !isAssetCrypto;
  const isUnavailableMarketClosed = !isAssetCrypto && isFalse(systemStatus.data?.securitiesTradingOpen);
  // user is only onboarded for one product, but in the combined experience, can still access all assets here in the modal
  const isUnavailableOnboarding = (!isAssetCrypto && !isOnboardedSecurities) || (isAssetCrypto && !isOnboardedCrypto);

  return (
    <Flex direction="column" w="full" alignItems="center">
      {isUnavailableOnboarding && (
        <>
          {isRejectedOrPending && (
            <Flex direction="column" gap={4} mb="4">
              {isRejectedOrPendingSecurities && !isAssetCrypto && (
                <KycAlert product="securities" onboardingStatus={onboardingStatusSecurities} />
              )}

              {isRejectedOrPendingCrypto && isAssetCrypto && (
                <KycAlert product="crypto" onboardingStatus={onboardingStatusCrypto} />
              )}
            </Flex>
          )}

          {!isRejectedOrPending && (
            <Text
              color="brand.500"
              onClick={() => {
                resumeOnboarding();
              }}
              cursor="pointer"
              _hover={{ textDecoration: 'underline' }}
              mb="4"
            >
              Please create a {isAssetCrypto ? 'crypto' : 'securities'} trading account to continue
            </Text>
          )}
        </>
      )}

      <Button
        // each form gets a unique id to keep both onsubmit events from firing
        form={transactionType === 'buy' ? 'buyForm' : 'sellForm'}
        isDisabled={
          !isFormValid({ getValues, getFieldState, transactionType }) ||
          isSystemDownByFeature() ||
          isUnavailableRiskTolerance ||
          isUnavailableMarketClosed ||
          isUnavailableOnboarding
        }
        type="submit"
        w="full"
        variant="outline"
        onClick={handlePreviewTransactionClick}
      >
        Preview order
      </Button>
    </Flex>
  );
};
StartTransaction.FooterContent = FooterContent;
