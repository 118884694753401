import { CfInput, CfSelect } from '@cryptofi/core-ui';
import { Camelized } from 'humps';
import { capitalize, toLower, upperFirst } from 'lodash';
import { FormEvent, useEffect } from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useIMask } from 'react-imask';

import { KycFormField } from '~/types';

import { KycFormValues } from './kycSchema';

// possible field types from the API
type apiFieldTypes = 'date' | 'email' | 'int' | 'select' | 'ssn' | 'phone' | 'postalCode' | 'text';

// convert valueType from API into the appropriate input type
const getInputType = ({ valueType, name }: { valueType: apiFieldTypes; name: string }) => {
  if (name === 'incomePerYearInteger' || name === 'netWorthInteger') {
    return 'currency';
  }

  switch (valueType) {
    case 'date':
      return 'date';
    case 'email':
      return 'email';
    case 'int':
      return 'number';
    case 'phone':
      return 'phone';

    // ssn, phone, postalCode, .etc
    default:
      return 'text';
  }
};

const DynamicInput = ({
  field,
  register,
  errors,
  setValue,
  watch,
}: {
  field: Camelized<KycFormField>;
  register: UseFormRegister<KycFormValues>;
  errors: FieldErrors;
  setValue: UseFormSetValue<KycFormValues>;
  watch?: UseFormWatch<KycFormValues>; // optional prop passed from useFormContext to enable default values and/or make the input keep user entered value when input remounts/rerenders
}) => {
  const maskLookup = {
    // keys must match a corresponding field name in KycSchema
    ssn: useIMask({ mask: '000-00-0000' }),
    phone: useIMask({ mask: '(000) 000-0000' }),
    postalCode: useIMask({ mask: '00000' }),
  };

  const getMask = (valueType: apiFieldTypes) => {
    switch (valueType) {
      case 'ssn':
        return maskLookup.ssn;
      case 'phone':
        return maskLookup.phone;
      case 'postalCode':
        return maskLookup.postalCode;
      default:
        return null;
    }
  };

  const mask = getMask(field.valueType);
  const watchedValue = watch && watch(field.name as keyof KycFormValues);
  useEffect(() => {
    // conditions to set the default value for the masked input
    if (watchedValue && watchedValue !== '' && mask?.value === '') {
      mask.setUnmaskedValue(watchedValue);
    }
    // only run when watchedValue changes
  }, [watchedValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const inputType = getInputType({ valueType: field.valueType, name: field.name });
  const currencyProps = inputType === 'currency' ? { placeholder: '', allowDecimals: false } : {};

  const dynamicProps = mask
    ? {
        // allows using the return values of register()
        register: (name: keyof KycFormValues): React.ComponentProps<typeof CfInput> => {
          // eslint-disable-next-line unused-imports/no-unused-vars
          const { ref, ...rest } = register(name);
          return {
            onInput: (e: FormEvent<HTMLInputElement>) => {
              // using onInput here because using onChange interferes with clearing validation errors
              // and masked inputs need to have their values set manually
              // mask.unmaskedValue is buggy and can be empty when autocompleting via mobile device,
              // so we use the raw value instead and replace all non-numeric characters
              setValue(name as keyof KycFormValues, e.currentTarget.value.replace(/\D/g, ''));
            },
            ref: mask.ref as React.Ref<HTMLInputElement>,
            ...rest,
          };
        },
      }
    : { register };

  let formattedLabel: string = capitalize(field.label);
  // make sure Social Security is capitalized correctly
  if (field.name === 'ssn') {
    formattedLabel = formattedLabel.replace(/social security/gi, 'Social Security') as Capitalize<Lowercase<string>>;
  }

  if (field.valueType === 'select') {
    return (
      <CfSelect
        label={formattedLabel}
        name={field.name}
        errorMessage={errors[field.name]?.message as string}
        isRequired={field.required}
        register={register}
        defaultValue=""
      >
        <option disabled value="">
          Select...
        </option>

        {field.options?.map(({ name: optionName, value }) => {
          // use sentence case except for state and country
          const displayName = ['state', 'country'].includes(field.name) ? optionName : upperFirst(toLower(optionName));

          return (
            <option key={optionName} value={value}>
              {displayName}
            </option>
          );
        })}
      </CfSelect>
    );
  }

  return (
    <CfInput
      type={inputType}
      label={formattedLabel}
      name={field.name}
      errorMessage={errors[field.name]?.message as string}
      isRequired={field.required}
      defaultValue={(typeof watchedValue === 'string' && watchedValue && watchedValue.replace(/\D/g, '')) || ''}
      {...dynamicProps}
      {...currencyProps}
    />
  );
};

export default DynamicInput;
