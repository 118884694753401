import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import { CfPrettyWrap, IconHelp, uiColors, useCfBreakpoint } from '@cryptofi/core-ui';
import { ReactNode } from 'react';
import { FieldErrors, UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { uiConstants } from '~/theme';
import { KycFormField } from '~/types';

import DynamicInput from './DynamicInput';
import { KycFormValues } from './kycSchema';
import RiskRadioCardGroup from './RiskRadioCardGroup';

const KycSection = ({
  fields,
  register,
  errors,
  setValue,
  heading,
  helpText,
  getValues,
  watch,
}: {
  fields: KycFormField[];
  register: UseFormRegister<KycFormValues>;
  errors: FieldErrors;
  setValue: UseFormSetValue<KycFormValues>;
  heading: string;
  helpText?: ReactNode;
  getValues?: UseFormGetValues<KycFormValues>;
  watch?: UseFormWatch<KycFormValues>;
}) => {
  const { isAboveSmallBreakpoint } = useCfBreakpoint();
  const isFieldFullWidth = (field: KycFormField) => {
    return (
      isAboveSmallBreakpoint && (field.name === 'executiveOrShareholder' || field.name === 'workForExchangeOrBrokerage')
    );
  };

  return (
    <Box>
      <Flex alignItems="center" gap={2} mb={6}>
        <Heading as="h2" size="sm" color={uiColors.sonicSilver()}>
          {heading}
        </Heading>

        {helpText && (
          <Popover>
            <PopoverTrigger>
              <IconButton
                variant="ghost"
                aria-label="help"
                minWidth="0"
                height="auto"
                backgroundColor="transparent !important"
                icon={<IconHelp __css={{ path: { fill: `brand.500 !important` } }} />}
              />
            </PopoverTrigger>

            <PopoverContent boxShadow={uiConstants.boxShadow}>
              <PopoverArrow />

              <PopoverBody fontSize="xs">{helpText}</PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </Flex>

      <Grid templateColumns={isAboveSmallBreakpoint ? 'repeat(2, 1fr)' : '1fr'} gap={6}>
        {fields.map((field) => {
          if (field.name === 'riskTolerance') {
            return (
              <GridItem key={field.name} colSpan={isAboveSmallBreakpoint ? 2 : 1} w="full">
                <RiskRadioCardGroup
                  field={field}
                  register={register}
                  errors={errors}
                  setValue={setValue}
                  getValues={getValues}
                />
              </GridItem>
            );
          }

          const isFullWidth = isFieldFullWidth(field);

          return (
            <GridItem key={field.name} w="full" colSpan={isFullWidth ? 2 : 1}>
              <CfPrettyWrap isEnabled={isFullWidth}>
                <DynamicInput field={field} register={register} errors={errors} setValue={setValue} watch={watch} />
              </CfPrettyWrap>
            </GridItem>
          );
        })}
      </Grid>
    </Box>
  );
};

export default KycSection;
