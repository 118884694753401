import { useQuery } from '@tanstack/react-query';
import { camelizeKeys, Decamelized } from 'humps';
import { map } from 'lodash';

import { cryptoRefetchInterval } from '~/constants';
import { useAxiosContext, useFeatureSetEnabled, useGlobalStore } from '~/hooks';
import { apiRoutes } from '~/routes';
import { TokenPrice } from '~/types';

export const qkTokenPrices = 'qk:tokenPrices';

const tokenObjectToArray = (tokens: Decamelized<TokenPrice>) => map(tokens, (t) => t);

const useGetTokenPrices = (args?: { refetchInterval: number | false }) => {
  const [userAuthInfo] = useGlobalStore((state) => [state.userAuthInfo]);
  const { isEnabled } = useFeatureSetEnabled();
  const { apiClient } = useAxiosContext();
  const disableRefetch = localStorage.getItem('enableRefetch') === 'false';
  const refetchInterval = args?.refetchInterval ?? cryptoRefetchInterval;

  return useQuery({
    refetchInterval: disableRefetch ? false : refetchInterval,
    enabled: Boolean(userAuthInfo) && isEnabled(['crypto']),
    queryKey: [qkTokenPrices, refetchInterval],
    queryFn: async () => {
      return apiClient?.get(apiRoutes.price()).then((r) => {
        const camelized = camelizeKeys(tokenObjectToArray(r.data)) as TokenPrice[];

        return camelized.sort((a, b) => a?.baseToken.localeCompare(b?.baseToken));
      });
    },
  });
};

export default useGetTokenPrices;
