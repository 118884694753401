import { Flex, FlexProps, Heading, Skeleton, Stat, StatLabel, StatNumber, Text } from '@chakra-ui/react';
import { formatUsd, uiColors } from '@cryptofi/core-ui';

import { AssetLogo } from '~/components';
import { AllAssetIds, AssetType, ChartTimeRange } from '~/types';

interface Props extends FlexProps {
  view: 'portfolio' | 'asset';
  currentValue: string | number;
  isNetGain: boolean;
  delta: number;
  isLoaded: boolean;
  assetId?: AllAssetIds;
  assetType?: AssetType;
  selectedTimeRange?: ChartTimeRange;
  customTimeRangeLabel?: string;
}

// TODO: rename component when portoflio updates are completed
const CurrentValue = ({
  view,
  currentValue,
  isNetGain,
  delta,
  isLoaded,
  assetId,
  assetType,
  selectedTimeRange,
  customTimeRangeLabel,
}: Props) => {
  const timeRangeLookup = {
    pastDay: 'today',
    pastWeek: 'past week',
    pastMonth: 'past month',
    pastYear: 'past year',
    allTime: 'all time',
  };

  const getTimeRangeLabel = (timeRange: ChartTimeRange) => {
    return timeRangeLookup[timeRange];
  };

  const timeRangeText = customTimeRangeLabel || (selectedTimeRange && getTimeRangeLabel(selectedTimeRange)) || '';

  return (
    <Stat w="fit-content">
      <Skeleton h="full" w="fit-content" isLoaded={isLoaded}>
        <Flex gap="2" alignItems="center">
          <StatLabel>
            <Text>{view === 'portfolio' ? 'Total value' : 'Current value'}</Text>
          </StatLabel>

          <Heading lineHeight="1.5" fontSize="sm" color={isNetGain ? uiColors.mint() : uiColors.heroicRed()}>
            {formatUsd({
              amount: delta,
              signDisplay: true,
            })
              .toString()
              .concat(' ')}

            {timeRangeText}
          </Heading>
        </Flex>

        <StatNumber fontSize="xx-large" whiteSpace="nowrap">
          <Flex alignItems="center" gap="2">
            {assetId && assetType && <AssetLogo assetId={assetId} assetType={assetType} boxSize="6" />}

            {formatUsd({ amount: currentValue })}
          </Flex>
        </StatNumber>
      </Skeleton>
    </Stat>
  );
};

export default CurrentValue;
