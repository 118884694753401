'use client';

import {
  Button,
  CardHeader,
  Flex,
  Heading,
  Hide,
  Show,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { CfCard, CfSpinner, dateFormats, formatUsd, utcDateToLocal } from '@cryptofi/core-ui';
import { size } from 'lodash';
// eslint-disable-next-line no-restricted-imports
import NextLink from 'next/link';

import { AssetLogo, TransactionAccordionList, TransactionStatus } from '~/components';
import { investButtonLabels } from '~/constants';
import { useGetSearchTransactions, useGlobalModalContext } from '~/hooks';
import { uiRoutes } from '~/routes';
import { AssetType } from '~/types';

interface AssetTransactionHistoryTable {
  asset: string;
  assetType: AssetType;
  ownsCurrentAsset: boolean;
}

const AssetTransactionHistoryTable = ({ ownsCurrentAsset, asset, assetType }: AssetTransactionHistoryTable) => {
  const { onOpenModal } = useGlobalModalContext();

  const params = {
    asset,
    assetType: assetType === 'crypto' ? 'CRYPTO' : 'SECURITY',
  };

  const transactions = useGetSearchTransactions({
    params,
  });

  const listLimit = 5;

  const hasTransactions = Boolean(size(transactions.data?.items));

  return (
    <CfCard>
      <Flex direction="column" justifyContent="center" w="full">
        <CardHeader p="0" pb="4">
          <Flex justify="space-between">
            <Heading as="h1" size="md">
              Latest trades
            </Heading>

            {!ownsCurrentAsset && (
              <Button display={{ base: 'none', md: 'flex' }} onClick={onOpenModal}>
                {investButtonLabels.invest}
              </Button>
            )}
          </Flex>
        </CardHeader>

        {transactions.isLoading && (
          <Show above="md">
            <CfSpinner />
          </Show>
        )}

        <Show above="md">
          <TableContainer flex="1">
            {!transactions.isLoading && (
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Asset</Th>

                    <Th>ID</Th>

                    <Th>Type</Th>

                    <Th>Date</Th>

                    <Th>Amount</Th>

                    <Th>Status</Th>
                  </Tr>
                </Thead>

                <Tbody>
                  {/* show most recent {listLimit} number of transactions */}
                  {!hasTransactions && (
                    <Tr>
                      <Td textAlign="center" colSpan={100}>
                        No trades found
                      </Td>
                    </Tr>
                  )}

                  {transactions.data?.items?.slice(0, listLimit).map((transaction) => (
                    <Tr key={transaction.orderTxId}>
                      <Td>
                        <Flex alignItems="center" gap={4}>
                          <AssetLogo
                            assetId={transaction.asset || ''}
                            assetType={transaction.assetType === 'CRYPTO' ? 'crypto' : 'securities'}
                          />

                          {transaction.asset}
                        </Flex>
                      </Td>

                      <Td>{transaction.orderTxId}</Td>

                      <Td>{transaction.transactionName}</Td>

                      <Td>
                        {utcDateToLocal({
                          dateFormat: dateFormats.dateWithTime,
                          timeString: transaction.transactTime,
                        })}
                      </Td>

                      <Td>
                        {formatUsd({
                          amount: transaction.usdAmountGross || 0,
                        })}
                      </Td>

                      <Td>
                        <TransactionStatus transaction={transaction} />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
          </TableContainer>
        </Show>

        <Hide above="md">
          <TransactionAccordionList transactions={transactions} limit={listLimit} />
        </Hide>

        {hasTransactions && (
          <Button
            alignSelf="center"
            as={NextLink}
            href={uiRoutes.transactionHistory({ asset })}
            size="sm"
            variant="ghost"
            my="4"
            minWidth="10rem"
          >
            View all
          </Button>
        )}
      </Flex>
    </CfCard>
  );
};

export default AssetTransactionHistoryTable;
