import { invert } from 'lodash';

import {
  dividendStatusLookup as dividendStatus,
  orderStatusLookup as orderStatus,
  tokenIdLookup,
  tokenNameLookup,
} from '~/types';

import defaultEducationImages from './defaultEducationImages';
import { introContentsCrypto, introContentsSecurities } from './introductionContent';
import onboardingSteps from './onboardingSteps.json';
import usStates from './usStates.json';

// re export the following modules
export { defaultEducationImages, introContentsCrypto, introContentsSecurities, onboardingSteps, usStates };

// Yup email validation allows some formats we don't support, see https://github.com/jquense/yup/issues/507
// regex from https://stackoverflow.com/a/4964766
export const emailRegex = /^\S+@\S+\.\S+$/;

export const defaultStaleTime = 300_000; // 5 minutes, https://tanstack.com/query/latest/docs/framework/react/guides/important-defaults
export const cryptoRefetchInterval = 5_000; // 5 seconds, pickup new crypto prices
export const securitiesRefetchInterval = 20_000; // 20 seconds, pickup new securities prices
export const bankAccountRefetchInterval = 60_000; // 1 minute, pickup account balance changes
export const statusRefetchInterval = 30_000; // 30 seconds, pickup system status changes

export const defaultRecordsPerPage = 10;

export const investButtonLabels = {
  trade: 'Trade',
  invest: 'Invest',
};

export const defaultEducationImage = 'Crypto101';

export const cryptoUnavailableMessage = 'Crypto trading is temporarily unavailable';
export const securitiesUnavailableMessage = 'Securities trading is temporarily unavailable';
export const systemOnboardingUnavailableMessage = 'New account creation is temporarily unavailable';
export const cryptoOnboardingUnavailableMessage = 'Crypto onboarding is temporarily unavailable';
export const securitiesOnboardingUnavailableMessage = 'Securities onboarding is temporarily unavailable';

export const tokenIdToName = {
  [tokenIdLookup.BTC]: tokenNameLookup.Bitcoin,
  [tokenIdLookup.BCH]: tokenNameLookup.BitcoinCash,
  [tokenIdLookup.ETH]: tokenNameLookup.Ethereum,
  [tokenIdLookup.LTC]: tokenNameLookup.Litecoin,
  [tokenIdLookup.XLM]: tokenNameLookup.Stellar,
  [tokenIdLookup.LINK]: tokenNameLookup.Chainlink,
  [tokenIdLookup.AVAX]: tokenNameLookup.Avalanche,
  [tokenIdLookup.XRP]: tokenNameLookup.Ripple,
  [tokenIdLookup.ALGO]: tokenNameLookup.Algorand,
  [tokenIdLookup.MKR]: tokenNameLookup.Maker,
  [tokenIdLookup.SOL]: tokenNameLookup.Solana,
  [tokenIdLookup.DOGE]: tokenNameLookup.Dogecoin,
};

export const tokenNameToId = invert(tokenIdToName);

// Statuses are from this doc: https://www.notion.so/cryptofi/Transaction-Statuses-0a6df45ea62a4f13881d4da8a9bc6633?pvs=6&utm_content=0a6df45e-a62a-4f13-881d-4da8a9bc6633&utm_campaign=T02943LHG83&n=slack&n=slack_link_unfurl
export const orderStatusLookup = {
  [orderStatus.NONE]: 'Pending',
  [orderStatus.OK]: 'Complete',
  [orderStatus.OK_ERROR]: 'Order Failed',
  [orderStatus.OK_FRAUD]: 'Fraud',
  [orderStatus.OK_LIQUIDATED]: 'Liquidated',
  [orderStatus.OK_PARTIAL]: 'Complete',
  [orderStatus.CANCELED]: 'Order Failed',
  [orderStatus.RECEIVED]: 'Pending',
  [orderStatus.OK_BANK_TRANSFER]: 'Pending',
  [orderStatus.PARTIAL_FILL_BUY]: 'Pending',
  [orderStatus.PARTIAL_FILL_SELL]: 'Pending',
  [orderStatus.PENDING_ORDER]: 'Pending',
  [orderStatus.PENDING_SELL_SETTLEMENT]: 'Complete',
  [orderStatus.PENDING_BUY_SETTLEMENT_SELL_UNAVAILABLE]: 'Complete',
  [orderStatus.PENDING_BUY_SETTLEMENT]: 'Complete',
  [orderStatus.PENDING_BUY_SETTLEMENT_PARTIAL]: 'Complete',
  [orderStatus.PENDING_SELL_SETTLEMENT_PARTIAL]: 'Complete',
  [orderStatus.PENDING_XFR_SELL_SETTLED]: 'Complete',
  [orderStatus.ERROR]: 'Order failed',
  [orderStatus.ERROR_BUY_RECONCILED]: 'Order failed',
  [orderStatus.ERROR_BUY_OK_XFR]: 'Incomplete, contact support',
  [orderStatus.ERROR_BUY_ERROR_XFR]: 'Incomplete, contact support',
  [orderStatus.ERROR_SELL]: 'Order failed',
  [orderStatus.ERROR_XFR_OK_SELL]: 'Incomplete, contact support',
  [dividendStatus.PENDING_SETTLEMENT]: 'Pending',
  [dividendStatus.BANK_TRANSFER_ERROR]: 'Error',
};

export type OrderStatusKeys = keyof typeof orderStatusLookup;
