'use client';

import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { IconDiamonds, IconHelp, uiColors } from '@cryptofi/core-ui';

import { uiConstants } from '~/theme';

const PortfolioRecommendation = ({
  // TODO: remove hardcoded values once BE adds name and description to robo account object.
  roboPortfolio = {
    name: 'Balanced (mock data)',
    description:
      "(mock data) Based on your preferences, we've chosen a Balanced portfolio to help you grow your investments while managing risk, blending growth and stability for long-term results.",
  },
  variant = 'section',
}: {
  roboPortfolio?: { name: string; description: string };
  variant?: 'section' | 'button';
}) => {
  if (variant === 'button') {
    return (
      // TODO: update popover tooltip once product and design tells us what they want
      <Popover>
        <PopoverTrigger>
          <Button
            variant="ghost"
            aria-label="portfolio recommendation"
            w={{ base: 'full', lg: 'fit-content' }}
            border="1px solid"
            borderColor="rgb(225, 225, 225)"
            borderRadius="md"
            mb="6"
          >
            <Flex gap="2" alignItems="center">
              <Text color={uiColors.sonicSilver()} fontFamily="heading" fontSize="sm">
                {roboPortfolio.name}
              </Text>

              <IconHelp __css={{ path: { fill: `${uiColors.sonicSilver()} !important` } }} />
            </Flex>
          </Button>
        </PopoverTrigger>

        <PopoverContent boxShadow={uiConstants.boxShadow}>
          <PopoverArrow />

          <PopoverBody fontSize="sm">{roboPortfolio.description}</PopoverBody>
        </PopoverContent>
      </Popover>
    );
  }

  if (variant === 'section') {
    return (
      <Flex flexDir="column" bgColor={uiColors.lighthouse()} borderRadius="2xl" w="full" p="4">
        <Flex w="full" justifyContent="space-between" mb="4">
          {/* TODO: update borderColor to {uiColors.jupiter()} */}
          <Box border="1px solid" borderColor="rgb(225, 225, 225)" borderRadius="md" py="1" px="2">
            <Text color={uiColors.sonicSilver()} fontFamily="heading" fontSize="sm">
              {roboPortfolio.name}
            </Text>
          </Box>

          <IconDiamonds __css={{ path: { fill: uiColors.coolElegance() } }} boxSize="5" />
        </Flex>

        <Text mb="2">Why did we choose this portfolio for you?</Text>

        <Text color={uiColors.sonicSilver()}>{roboPortfolio.description}</Text>
      </Flex>
    );
  }

  return null;
};

export default PortfolioRecommendation;
