/**
 * @description Check if the user's is allowed to purchase a specific security.
 * This takes into account the user's risk tolerance, the security's risk level, and determines
 * if the security is within the user's risk tolerance.
 * @returns {boolean} - Whether the user is allowed to purchase the security.
 */

import { isEmpty } from 'lodash';

import { useGetUser, useOnboardingInfo } from '~/hooks';
import { SecurityAsset } from '~/types';

const useCanUserPurchaseSecurity = (securityInfo: SecurityAsset | undefined | Record<string, never>) => {
  const user = useGetUser();
  const { isOnboardedSecurities } = useOnboardingInfo();

  // prevent the risk profile from message being shown if the user is not onboarded
  if (!isOnboardedSecurities) {
    return true;
  }

  let isWithinRiskProfile = true;
  if (securityInfo && !isEmpty(securityInfo)) {
    // Default userRiskProfile to -1 if no risk profile is found
    // This would keep the user from investing in any security
    // NOTE: This should never happen as all users should have a risk profile from our db
    const userRiskProfile = user.data?.securitiesRiskProfile || -1;
    // Default securitiesRiskProfile to 5 if no risk profile is found as this is higher than any user's risk profile
    // Thus this would not allow the user to trade the asset
    //  NOTE: This should never happen as all securities should have a risk profile from our db
    const securitiesRiskProfile = (securityInfo as SecurityAsset).riskProfile || 5;
    isWithinRiskProfile = userRiskProfile >= securitiesRiskProfile;
  }
  return isWithinRiskProfile;
};

export default useCanUserPurchaseSecurity;
