import { useEffect, useState } from 'react';

import { useFeatureSetEnabled, useGetUser, useGlobalStore } from '~/hooks';
import { KycStatusLookup, kycStatusLookup, OnboardingStatus, Product } from '~/types';
import { hasAcceptedTermsForFeature } from '~/utils';

const stepNumberLookup: {
  [key: string]: number;
} = {
  notAcceptedTandC: 1,
  notStartedKyc: 2,
  // TODO: consider using 3 steps when selectProductsView is part of the flow
};

const getOnboardingStatus = ({
  kycStatus,
  hasAcceptedAllRequiredTerms,
}: {
  kycStatus?: KycStatusLookup | null;
  hasAcceptedAllRequiredTerms: boolean | undefined;
}) => {
  if (!kycStatus || !hasAcceptedAllRequiredTerms) {
    return 'notAcceptedTandC';
  }

  // map KYC status to onboarding status
  const statusLookup: Partial<Record<KycStatusLookup, OnboardingStatus>> = {
    [kycStatusLookup.NOT_STARTED]: 'notStartedKyc',
    [kycStatusLookup.PENDING]: 'pending',
    [kycStatusLookup.APPROVED]: 'approved',
    [kycStatusLookup.REJECTED]: 'rejected',
  };

  return statusLookup[kycStatus];
};

const useOnboardingInfo = () => {
  const user = useGetUser();
  const { isEnabled } = useFeatureSetEnabled();
  const [onboardingProducts, setOnboardingProducts, onboardingError, setOnboardingError] = useGlobalStore((state) => [
    state.onboardingProducts,
    state.setOnboardingProducts,
    state.onboardingError,
    state.setOnboardingError,
  ]);

  const [currentStep, setCurrentStep] = useState(1);

  const hasAcceptedSecuritiesTerms =
    user?.data &&
    isEnabled(['securities']) &&
    hasAcceptedTermsForFeature({ user: user.data, featureSet: 'securities' });

  const hasAcceptedCryptoTerms =
    user?.data && isEnabled(['crypto']) && hasAcceptedTermsForFeature({ user: user.data, featureSet: 'crypto' });

  const onboardingStatusCrypto = getOnboardingStatus({
    kycStatus: user?.data?.cryptoKycStatus,
    hasAcceptedAllRequiredTerms: hasAcceptedCryptoTerms,
  });

  const onboardingStatusSecurities = getOnboardingStatus({
    kycStatus: user?.data?.securitiesKycStatus,
    hasAcceptedAllRequiredTerms: hasAcceptedSecuritiesTerms,
  });

  // assumes that if onboardingProducts has both crypto and securities, onboardingStatusCrypto and onboardingStatusSecurities are the same
  const getCurrentOnboardingStatus = () => {
    if (onboardingProducts.includes('crypto')) {
      return onboardingStatusCrypto;
    } else if (onboardingProducts.includes('securities')) {
      return onboardingStatusSecurities;
    } else {
      return onboardingStatusCrypto || onboardingStatusSecurities;
    }
  };

  const currentOnboardingStatus = getCurrentOnboardingStatus();

  // determine the current status and step of onboarding
  useEffect(() => {
    if (currentOnboardingStatus) {
      setCurrentStep(stepNumberLookup[currentOnboardingStatus]);
    }
  }, [currentOnboardingStatus]);

  const isOnboardedCrypto = Boolean(onboardingStatusCrypto === 'approved');
  const isOnboardedSecurities = Boolean(onboardingStatusSecurities === 'approved');
  const isOnboarded = isOnboardedCrypto || isOnboardedSecurities;

  const hasNewCryptoTerms =
    isEnabled(['crypto']) && user?.data?.cryptoKycStatus === 'APPROVED' && !hasAcceptedCryptoTerms;
  const hasNewSecuritiesTerms =
    isEnabled(['securities']) && user?.data?.securitiesKycStatus === 'APPROVED' && !hasAcceptedSecuritiesTerms;
  const hasNewTerms =
    hasNewSecuritiesTerms ||
    hasNewCryptoTerms ||
    ((user?.data?.cryptoKycStatus === 'APPROVED' || user?.data?.securitiesKycStatus === 'APPROVED') &&
      !user?.data?.termsAndConditions?.investifi?.dateAccepted);

  const isRejectedOrPendingCrypto = Boolean(['pending', 'rejected'].includes(onboardingStatusCrypto || ''));
  const isRejectedOrPendingSecurities = Boolean(['pending', 'rejected'].includes(onboardingStatusSecurities || ''));
  const isRejectedOrPending = isRejectedOrPendingCrypto || isRejectedOrPendingSecurities;

  useEffect(() => {
    // make sure this logic doesnt override the user's selection in selectProductsView
    if (onboardingProducts.length === 0) {
      const products: Product[] = [];
      if (isEnabled(['crypto']) && !isEnabled(['securities'])) {
        products.push('crypto');
      } else if (isEnabled(['securities']) && !isEnabled(['crypto'])) {
        products.push('securities');
      } else if (isEnabled(['crypto', 'securities'])) {
        // handle setting onboarding products when new terms need to be accepted
        if (hasNewTerms) {
          if (hasNewCryptoTerms || isOnboardedCrypto) {
            products.push('crypto');
          }
          if (hasNewSecuritiesTerms || isOnboardedSecurities) {
            products.push('securities');
          }
        } else {
          if (
            !isOnboardedCrypto &&
            (onboardingStatusCrypto === 'notStartedKyc' || isOnboardedSecurities || isRejectedOrPendingSecurities)
          ) {
            products.push('crypto');
          }
          if (
            !isOnboardedSecurities &&
            (onboardingStatusSecurities === 'notStartedKyc' || isOnboardedCrypto || isRejectedOrPendingCrypto)
          ) {
            products.push('securities');
          }
        }
      }
      // prevent infinite loop
      if (products.length > 0) {
        setOnboardingProducts(products);
      }
    }
  }, [
    hasAcceptedCryptoTerms,
    hasAcceptedSecuritiesTerms,
    onboardingProducts,
    onboardingStatusCrypto,
    onboardingStatusSecurities,
    isEnabled,
    isOnboardedSecurities,
    isOnboardedCrypto,
    isRejectedOrPendingCrypto,
    isRejectedOrPendingSecurities,
    hasNewCryptoTerms,
    hasNewSecuritiesTerms,
    hasNewTerms,
    setOnboardingProducts,
  ]);

  const getSectionProgress = (step: number) => {
    if (step > currentStep) {
      return 0;
    }

    if (step === currentStep) {
      return 10;
    }

    if (step < currentStep || isOnboarded) {
      return 100;
    }
  };

  return {
    currentOnboardingStatus,
    onboardingStatusCrypto,
    onboardingStatusSecurities,
    isOnboarded, // onboarded for at least one product
    isOnboardedCrypto,
    isOnboardedSecurities,
    isRejectedOrPending,
    isRejectedOrPendingCrypto,
    isRejectedOrPendingSecurities,
    hasNewTerms,
    onboardingProducts, // is redundant with useGlobalStore but nice to have here for now
    setOnboardingProducts, // is redundant with useGlobalStore but nice to have here for now
    onboardingError, // is redundant with useGlobalStore but nice to have here for now
    setOnboardingError, // is redundant with useGlobalStore but nice to have here for now
    currentStep,
    totalSteps: 2,
    getSectionProgress,
  };
};

export default useOnboardingInfo;
