import { camelize } from 'humps';
import { every } from 'lodash';

// eslint-disable-next-line no-restricted-imports
import { TermsAndConditionTypes } from '~/codegen/types';
import { Product, User } from '~/types';

const requiredTermsForFeatureSet: Record<Product, TermsAndConditionTypes[]> = {
  securities: [
    TermsAndConditionTypes.Securities,
    TermsAndConditionTypes.InvestifiBrokerageLicense,
    TermsAndConditionTypes.AdvisifiBrokerageLicense,
  ],
  crypto: [TermsAndConditionTypes.Crypto],
  robo: [],
};

/**
 * @description For a given user and feature, ensure that the correct terms fields
 * for that feature have been accepted. The specific terms fields required for a given
 * feature are stored in this util.
 */
export const hasAcceptedTermsForFeature = ({
  user,
  featureSet,
}: {
  user: Pick<User, 'termsAndConditions'>;
  featureSet: Product;
}) =>
  every(
    requiredTermsForFeatureSet[featureSet].map((termsType) =>
      Boolean(user?.termsAndConditions?.[camelize(termsType)]?.dateAccepted),
    ),
  );
