import { useMutation } from '@tanstack/react-query';
import { camelizeKeys, decamelizeKeys } from 'humps';

import { SecuritiesBuyOrderRequestBody } from '~/codegen/types';
import { useAxiosContext } from '~/hooks';
import { apiRoutes } from '~/routes';
import { Order } from '~/types';
import { ignoreUpperCaseKeys } from '~/utils';

const usePostBuySecurity = () => {
  const { apiClient } = useAxiosContext();

  return useMutation({
    mutationFn: async (body: SecuritiesBuyOrderRequestBody) => {
      return apiClient?.post(apiRoutes.buySecurity(), decamelizeKeys(body)).then((r) => {
        const data = camelizeKeys(r.data, {
          process: ignoreUpperCaseKeys,
        }) as Order;

        return data;
      });
    },
  });
};

export default usePostBuySecurity;
