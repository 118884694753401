import { useQuery } from '@tanstack/react-query';
import { camelizeKeys, decamelizeKeys } from 'humps';

import { defaultRecordsPerPage } from '~/constants';
import { useAxiosContext, useGlobalStore } from '~/hooks';
import { apiRoutes } from '~/routes';
import { TransactionHistoryFormData, TransactionPaginator } from '~/types';
import { ignoreUpperCaseKeys } from '~/utils';

interface GetSearchTransactionsProps {
  params?: TransactionHistoryFormData;
  recordsPerPage?: number;
  enabled?: boolean;
}

export const qkSearchTransactions = 'qk:searchTransactions';

const useGetSearchTransactions = ({
  params,
  recordsPerPage = defaultRecordsPerPage,
  enabled = true,
}: GetSearchTransactionsProps = {}) => {
  const userAuthInfo = useGlobalStore((state) => state.userAuthInfo);
  const { apiClient } = useAxiosContext();

  return useQuery({
    enabled: Boolean(userAuthInfo) && enabled,
    queryKey: [qkSearchTransactions, params, recordsPerPage],
    queryFn: async () => {
      return apiClient
        ?.get(apiRoutes.transactions(), {
          params: decamelizeKeys({
            scanIndexForward: false, // This sorts in descending order
            perPage: recordsPerPage,
            ...params,
          }),
        })
        .then((r) => camelizeKeys(r.data, { process: ignoreUpperCaseKeys }) as TransactionPaginator);
    },
  });
};

export default useGetSearchTransactions;
