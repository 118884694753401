import { useQuery } from '@tanstack/react-query';
import { camelizeKeys } from 'humps';

import { useAxiosContext, useFeatureSetEnabled, useGlobalStore } from '~/hooks';
import { apiRoutes } from '~/routes';
import { SecurityAsset } from '~/types';

export const qkSearchSecurities = 'qk:searchSecurities';

const useGetSearchSecurities = ({ query, refetchInterval }: { query: string; refetchInterval?: number | false }) => {
  const { apiClient } = useAxiosContext();
  const [userAuthInfo] = useGlobalStore((state) => [state.userAuthInfo]);
  const disableRefetch = localStorage.getItem('enableRefetch') === 'false';
  const { isEnabled } = useFeatureSetEnabled();

  return useQuery({
    refetchInterval: disableRefetch ? false : refetchInterval,
    enabled: Boolean(userAuthInfo) && isEnabled(['securities']),
    queryKey: [qkSearchSecurities, query, refetchInterval],
    queryFn: async () => {
      // prevent 500 errors when no search query is provided
      if (!query) {
        return [];
      }

      const response = await apiClient?.get(apiRoutes.redis.searchSecurities({ query }));

      const formattedData: SecurityAsset[] = response?.data.map((value: SecurityAsset) => {
        return camelizeKeys(value);
      });

      return formattedData;
    },
  });
};

export default useGetSearchSecurities;
