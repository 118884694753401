/*
 * This hook is used to post telemetry events to the server.
 * It uses the react-query library to handle the API request.
 *
 * @returns {trackEvent} - A function that takes an eventType and eventProperties and sends a POST request to the server.
 *
 * @example
 * const { trackEvent } = usePostTelemetryEvent();
 * trackEvent(TelemetryClientSideEventsEnum.ClickedMainNavCryptoHomeClient, null);
 */
import { useMutation } from '@tanstack/react-query';
import { decamelizeKeys } from 'humps';

import { useAxiosContext } from '~/hooks';
import { apiRoutes } from '~/routes';
import { TelemetryEvents } from '~/types';

interface TelemetryEventProperties {
  [key: string]: any;
}

interface PostTelemetryEventArgs {
  eventType: TelemetryEvents;
  eventProperties?: TelemetryEventProperties;
}

const usePostTelemetryEvent = () => {
  const { apiClient } = useAxiosContext();

  // Define the mutation
  const { mutate } = useMutation<void, Error, PostTelemetryEventArgs>({
    mutationFn: async ({ eventType, eventProperties = {} }) => {
      // Filter out any empty values from the eventProperties
      const filteredEventProperties: TelemetryEventProperties = Object.entries(eventProperties).reduce(
        (acc: TelemetryEventProperties, [key, value]) => {
          if (value !== '') {
            acc[key] = value;
          }
          return acc;
        },
        {},
      );

      // Then decamelize the keys of the eventProperties
      const decamelizedEventProperties = eventProperties ? decamelizeKeys(filteredEventProperties) : {};

      // Send the POST request to the server with the event_type as a query parameter and the eventProperties in the body
      await apiClient?.post(apiRoutes.telemetryEvent(), decamelizedEventProperties, {
        params: {
          // eslint-disable-next-line camelcase
          event_type: eventType,
        },
      });
    },
  });

  // Define trackEvent function that wraps the mutate function
  const trackEvent = (eventType: TelemetryEvents, eventProperties?: TelemetryEventProperties) => {
    mutate({
      eventType,
      eventProperties,
    });
  };

  // Return trackEvent instead of the raw mutation object
  return { trackEvent };
};

export default usePostTelemetryEvent;
