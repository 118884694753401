import { useQuery } from '@tanstack/react-query';
import { camelizeKeys } from 'humps';

import { useAxiosContext, useGlobalStore } from '~/hooks';
import { apiRoutes } from '~/routes';
import { FeeBreakdown } from '~/types';
import { ignoreUpperCaseKeys } from '~/utils';

export const qkFees = 'qk:fees';

const useGetFees = ({ price, enabled = true }: { price: number; enabled?: boolean }) => {
  const userAuthInfo = useGlobalStore((state) => state.userAuthInfo);
  const { apiClient } = useAxiosContext();

  return useQuery({
    enabled: Boolean(userAuthInfo) && enabled,
    queryKey: [qkFees, price],
    queryFn: async () => {
      return apiClient?.get(apiRoutes.fees(price)).then((r) => {
        const data = camelizeKeys(r.data, {
          process: ignoreUpperCaseKeys,
        }) as FeeBreakdown;

        return data;
      });
    },
  });
};

export default useGetFees;
