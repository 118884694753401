import { ThemeOverride } from '@chakra-ui/react';
import { coreTheme, uiColors } from '@cryptofi/core-ui';
import { Chivo_Mono as Mono, DM_Sans as Sans } from 'next/font/google';

import { BrandColors } from '~/types';

export const uiConstants = {
  boxShadow: `0 8px 16px 0 ${uiColors.black(0.08)}`,
  transitionDuration: '0.25s',
  backdropBlur: 'blur(8px)',
  hoverBackground: 'gray.50',
};

// using next/font with Chakra https://github.com/chakra-ui/chakra-ui/discussions/7235#discussioncomment-4685189
// font weights & styles
const normal = Sans({
  subsets: ['latin'],
  weight: '400',
});

const bold = Sans({
  subsets: ['latin'],
  weight: '700',
});

const mono = Mono({
  subsets: ['latin'],
  weight: '400',
});

const fontStyles = {
  // disable font synthesis to prevent artifacts on synthesized fonts: bold, italic, etc.
  // https://developer.mozilla.org/en-US/docs/Web/CSS/font-synthesis
  '*': {
    fontSynthesis: 'style !important',
  },
};

// with the exception of brand colors, this theme is for app styles that cannot be overridden
const appTheme = ({ brand }: { brand: BrandColors }): ThemeOverride => {
  return {
    fonts: {
      heading: bold.style.fontFamily,
      body: normal.style.fontFamily,
      mono: mono.style.fontFamily,
    },
    colors: {
      brand: brand as Record<string, any>, // widen the BrandColors type to match ThemeOverride
    },
    config: {
      initialColorMode: 'light',
      useSystemColorMode: false,
    },
    components: {
      ...coreTheme.components,
    },
    styles: {
      global: {
        ...coreTheme.styles.global,
        ...fontStyles,
        body: {
          color: uiColors.black(),
          backgroundColor: uiColors.white(),
        },

        '.cf-tag': {
          fontFamily: bold.style.fontFamily,
          fontWeight: 700,
        },

        // dev console styles
        '.dev-checkbox.green': {
          '.chakra-checkbox__control': {
            borderColor: 'green.500 !important',
            border: '1px solid',
          },
          '.chakra-checkbox__control[data-checked], .chakra-checkbox__control:hover': {
            color: 'green.500',
            borderColor: 'green.500',
            bgColor: 'transparent',
          },
          '.chakra-checkbox__control[data-checked]': {
            bgColor: 'green.900 !important',
          },
        },

        '.dev-checkbox.yellow': {
          '.chakra-checkbox__control': {
            borderColor: 'yellow.500 !important',
            border: '1px solid',
          },
          '.chakra-checkbox__control[data-checked], .chakra-checkbox__control:hover': {
            color: 'yellow.500',
            borderColor: 'yellow.500',
            bgColor: 'transparent',
          },
          '.chakra-checkbox__control[data-checked]': {
            bgColor: 'yellow.900 !important',
          },
        },

        // hide the button for React Query devtools
        '.tsqd-open-btn, .tsqd-open-btn-container': {
          display: 'none !important',
        },

        // show outlines for debugging in dev
        '.show-outlines *:not(.dev-console *)': {
          outline: '1px dashed',
          outlineColor: 'purple.500',
        },
        // nested .chakra-portal selector applies mainly to tooltips, .tsqd selectors are for React Query devtools
        '.show-outlines .dev-console, .show-outlines .chakra-portal .chakra-portal *, .tsqd-main-panel, .tsqd-main-panel *':
          {
            outline: 'none !important',
          },

        // show error boundaries for debugging in dev
        '.show-error-boundaries': {
          '.error-boundary': {
            position: 'relative',
            outline: '1px dashed',
            outlineColor: 'red.500',
            _before: {
              content: '"Error boundary"',
              position: 'absolute',
              right: 1,
              top: 0,
              fontSize: '10px',
              color: 'red.500',
              fontFamily: 'monospace',
            },
          },
        },
      },
    },
  };
};

export default appTheme;
