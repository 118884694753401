import { Box, Flex, Heading, Skeleton, Table, Tbody, Text, Tr } from '@chakra-ui/react';
import { formatUsd, uiColors } from '@cryptofi/core-ui';
import { useQueryClient } from '@tanstack/react-query';
import Big from 'big.js';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useFormContext } from 'react-hook-form';

import { AssetLogo, LabelTd, ValueTd } from '~/components';
import {
  qkUserBankAccounts,
  useGetFees,
  useGetSecuritySipPrice,
  useGetUserBankAccounts,
  useGlobalStore,
  useInvestModalContext,
  useTransactionHandlers,
} from '~/hooks';
import { Order } from '~/types';
import { logError } from '~/utils';

import ConfirmTransactionButton from './ConfirmTransaction';
import OrderSummary from './OrderSummary';
import { TransactionFormValues } from './transactionSchema';
import { getNbboPriceOrFallback, getTransactionPrice } from './utils';

const ReviewTransaction = () => {
  const {
    buyPrice,
    buyCurrencyAmount,
    sellPrice,
    sellCurrencyAmount,
    setNetBuyQuantity,
    transactionType,
    selectedAsset,
    selectedSecurity,
  } = useInvestModalContext();
  const bankAccounts = useGetUserBankAccounts();

  const isCrypto = selectedAsset?.type === 'crypto';
  const isBuy = transactionType === 'buy';

  const { getValues } = useFormContext();
  const { buyAccountId, buyIsUsd, sellAccountId, sellIsUsd } = getValues() as TransactionFormValues;

  const sip = useGetSecuritySipPrice({
    ticker: selectedAsset?.id || '',
    enabled: !isCrypto,
  });

  const fees = useGetFees({
    price: isBuy
      ? getTransactionPrice({ currencyAmount: buyCurrencyAmount, price: buyPrice, isUsd: buyIsUsd })
      : getTransactionPrice({ currencyAmount: sellCurrencyAmount, price: sellPrice, isUsd: sellIsUsd }),
  });

  useEffect(() => {
    if (isBuy) {
      setNetBuyQuantity(
        Big(isCrypto ? fees.data?.txNet || 0 : buyCurrencyAmount)
          .div(buyPrice)
          .toNumber(),
      );
    }
  }, [setNetBuyQuantity, isBuy, buyPrice, fees.data?.txNet, isCrypto, buyCurrencyAmount]);

  const selectedAccount = bankAccounts.data?.find((account) => {
    const accountId = isBuy ? buyAccountId : sellAccountId;
    return account.accountId === accountId;
  });

  const { accountDescription, displayAccountNumber, availableBalance } = selectedAccount || {};

  // TODO error state
  const transactionFee = isCrypto ? fees?.data?.txFee || 0 : 0;
  let transactionAmount = 0;
  if (isCrypto && fees?.data?.txNet) {
    transactionAmount = Number(fees?.data?.txNet);
  }
  if (!isCrypto && fees?.data?.txGross) {
    transactionAmount = Number(fees?.data?.txGross);
  }

  const securityPrice = getNbboPriceOrFallback({ sip, selectedSecurity: selectedSecurity?.data });

  return (
    // negative margin extends background color outside of the modal's default padding
    <Flex
      mx="-6"
      my="-2"
      px="6"
      py="4"
      borderTop="solid 1px"
      borderColor={uiColors.coolElegance()}
      bg={uiColors.lighthouse()}
    >
      <Flex flexDirection="column" m="-6" p="6" h="100%" w="calc(100% + 4rem)" position="relative">
        <Table variant="unstyled">
          <Tbody>
            <Tr>
              <LabelTd>Asset price</LabelTd>

              <ValueTd>
                <Skeleton isLoaded={Boolean(sip.data) && Boolean(selectedAsset?.id)}>
                  {!isCrypto && formatUsd({ amount: securityPrice ?? 0 })}
                </Skeleton>

                {isCrypto && formatUsd({ amount: isBuy ? buyPrice : sellPrice })}
              </ValueTd>
            </Tr>

            <Tr>
              <LabelTd>{isBuy ? 'Pay with' : 'Receive in'}</LabelTd>

              <ValueTd>
                <Flex position="relative" top={accountDescription ? 2.5 : 0}>
                  <Text
                    color={uiColors.sonicSilver()}
                    fontSize="sm"
                    position="absolute"
                    top="-5"
                    right="0"
                    fontFamily="body"
                    whiteSpace="nowrap"
                    bg={uiColors.lighthouse()}
                    pb="2px" // ensures the dotted line gets covered
                    pl="3"
                  >
                    {accountDescription}
                  </Text>

                  <Text>
                    {/* eslint-disable-next-line react/jsx-newline */}
                    **{displayAccountNumber} - {formatUsd({ amount: availableBalance || '' })}
                  </Text>
                </Flex>
              </ValueTd>
            </Tr>

            <Tr>
              <LabelTd>Amount</LabelTd>

              <ValueTd>
                <Skeleton isLoaded={!isCrypto || Boolean(fees?.data)}>
                  {formatUsd({ amount: transactionAmount, precision: 2 })}
                </Skeleton>
              </ValueTd>
            </Tr>

            {transactionFee !== 0 && (
              <Tr>
                <LabelTd>Transaction fee</LabelTd>

                <ValueTd>
                  <Skeleton isLoaded={!isCrypto || Boolean(fees?.data)}>
                    {formatUsd({ amount: transactionFee })}
                  </Skeleton>
                </ValueTd>
              </Tr>
            )}
          </Tbody>
        </Table>

        {!isCrypto && (
          <OrderSummary
            assetId={selectedAsset?.id}
            transactionAmount={transactionAmount}
            sipData={sip.data}
            isBuy={isBuy}
            securityPrice={securityPrice}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default ReviewTransaction;

// external sub components

const HeaderContent = () => {
  const {
    selectedAsset,
    selectedSecurity,
    sellQuantity,
    transactionType,
    netBuyQuantity,
    buyPrice,
    buyCurrencyAmount,
    sellPrice,
    sellCurrencyAmount,
  } = useInvestModalContext();
  const { getValues } = useFormContext();
  const { buyIsUsd, sellIsUsd } = getValues() as TransactionFormValues;
  const assetIsCrypto = selectedAsset?.type === 'crypto';

  const sip = useGetSecuritySipPrice({
    ticker: selectedAsset?.id || '',
    enabled: !assetIsCrypto,
  });
  const isBuy = transactionType === 'buy';
  const fees = useGetFees({
    price: isBuy
      ? getTransactionPrice({ currencyAmount: buyCurrencyAmount, price: buyPrice, isUsd: buyIsUsd })
      : getTransactionPrice({ currencyAmount: sellCurrencyAmount, price: sellPrice, isUsd: sellIsUsd }),
  });

  let transactionAmount = 0;
  if (assetIsCrypto && fees?.data?.txNet) {
    transactionAmount = Number(fees?.data?.txNet);
  }
  if (!assetIsCrypto && fees?.data?.txGross) {
    transactionAmount = Number(fees?.data?.txGross);
  }

  const securityPrice = getNbboPriceOrFallback({ sip, selectedSecurity: selectedSecurity?.data });

  return (
    <Flex pt="8" pb="2" gap="4" justifyContent="space-between" alignItems="center">
      <AssetLogo assetId={selectedAsset!.id} assetType={selectedAsset!.type} showId showName logoSize="10" />

      <Heading as="h2" textAlign="right" ml="4">
        <Text color={uiColors.sonicSilver()} fontSize="md">
          {isBuy ? 'Purchasing' : 'Selling'}
        </Text>

        {assetIsCrypto && (
          <Text fontSize="2xl">
            {assetIsCrypto && `${isBuy ? netBuyQuantity.toFixed(8) : sellQuantity.toFixed(8)}`}
          </Text>
        )}

        {!assetIsCrypto && (
          <Skeleton isLoaded={Boolean(securityPrice && transactionAmount)} height="2rem">
            <Text fontSize="2xl">{securityPrice ? Big(transactionAmount).div(securityPrice).toFixed(5) : '-'}</Text>
          </Skeleton>
        )}
      </Heading>
    </Flex>
  );
};
ReviewTransaction.HeaderContent = HeaderContent;

const FooterContent = () => {
  const [addClientOrderId] = useGlobalStore((state) => [state.addClientOrderId]);
  const {
    setModalView,
    buyPrice,
    buyCurrencyAmount,
    selectedAsset,
    buyCrypto,
    buySecurity,
    buyQuantity,
    transactionType,
    sellCurrencyAmount,
    sellPrice,
    sellCrypto,
    sellQuantity,
    sellSecurity,
    selectedSecurity,
    transactionState,
  } = useInvestModalContext();
  const bankAccounts = useGetUserBankAccounts();

  const queryClient = useQueryClient();
  const { buyAccountId, buyIsUsd, sellAccountId, sellIsUsd } = useFormContext().getValues() as TransactionFormValues;
  const isBuy = transactionType === 'buy';

  const fees = useGetFees({
    price: isBuy
      ? getTransactionPrice({ currencyAmount: buyCurrencyAmount, price: buyPrice, isUsd: buyIsUsd })
      : getTransactionPrice({ currencyAmount: sellCurrencyAmount, price: sellPrice, isUsd: sellIsUsd }),
    enabled: selectedAsset?.type === 'crypto',
  });

  const { buyCryptoHandler, sellCryptoHandler, buySecurityHandler, sellSecurityHandler } = useTransactionHandlers();

  interface BankAccount {
    accountId: string;
    accountType?: string;
  }

  const bankAccount = bankAccounts.data?.find((account: BankAccount) => {
    const accountId = isBuy ? buyAccountId : sellAccountId;
    return account.accountId === accountId;
  });

  const clearQueryCache = () => {
    // clear the bank accounts cache so that the user's available USD balance is updated
    // this should happen outside of the interval, as the bank accounts will be updated at this point
    // and these routes may have rate limiting restrictions based on the FI
    queryClient.invalidateQueries({
      predicate: (query) => {
        return query.queryKey.includes(qkUserBankAccounts);
      },
    });
  };

  const confirmTransaction = () => {
    const assetIsCrypto = selectedAsset?.type === 'crypto';

    if (bankAccount?.accountType && selectedAsset) {
      const args = {
        accountType: bankAccount.accountType,
        assetId: selectedAsset.id,
        orderPrice: fees.data!.txGross,
        onSettled: (transaction: Order | undefined) => {
          // Store the client order id so that polling will be activated until it's completed
          // See useBalanceAndOrdersPolling hook for more docs
          if (transaction?.txId) {
            addClientOrderId(transaction.txId);
          }
          setModalView('transactionResults');
          clearQueryCache();
        },
      };

      /// buy crypto
      if (isBuy && assetIsCrypto) {
        buyCryptoHandler({
          ...args,
          accountId: buyAccountId,
          assetId: selectedAsset.id,
          mutateFn: buyCrypto,
          quantity: buyQuantity,
        });
      }

      // sell crypto
      if (!isBuy && assetIsCrypto) {
        sellCryptoHandler({
          ...args,
          accountId: sellAccountId,
          assetId: selectedAsset.id,
          mutateFn: sellCrypto,
          quantity: sellQuantity,
        });
      }

      // buy security
      if (isBuy && !assetIsCrypto) {
        buySecurityHandler({
          ...args,
          accountId: buyAccountId,
          assetId: selectedAsset.id,
          mutateFn: buySecurity,
          quantity: buyQuantity,
          securitiesIdentifier: selectedSecurity?.data?.securitiesIdentifier!,
        });
      }

      // sell security
      if (!isBuy && !assetIsCrypto) {
        sellSecurityHandler({
          ...args,
          accountId: sellAccountId,
          assetId: selectedAsset.id,
          mutateFn: sellSecurity,
          quantity: sellQuantity,
          securitiesIdentifier: selectedSecurity?.data?.securitiesIdentifier!,
        });
      }
    }
  };

  return (
    <Box className="error-boundary" width="full">
      <ErrorBoundary
        onError={(error) => {
          logError({ error });
        }}
        fallback={
          <Flex>
            <Text fontSize="md" color={uiColors.heroicRed()}>
              Error: There was an error generating a preview of this order.
            </Text>
          </Flex>
        }
      >
        <ConfirmTransactionButton
          fees={fees}
          isLoading={Boolean(transactionState?.isPending)}
          transactionType={transactionType}
          bankAccount={bankAccount}
          confirmTransaction={confirmTransaction}
        />
      </ErrorBoundary>
    </Box>
  );
};
ReviewTransaction.FooterContent = FooterContent;
