'use client';

import { Flex } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { AssetsSearch } from '~/components';
import { useInvestModalContext } from '~/hooks';
import { AllAssetIds, AssetType } from '~/types';

export default function InvestAssetsSearch() {
  const {
    setModalView,
    setBuyCurrency,
    setSelectedAsset,
    transactionType,
    setSellCurrency,
    amountAvailableAsset,
    amountAvailableUsd,
  } = useInvestModalContext();

  const { setValue } = useFormContext();

  const setSearchSelectedAsset = ({ assetId, assetType }: { assetId: AllAssetIds; assetType: AssetType }) => {
    if (transactionType === 'buy') {
      setSelectedAsset({ id: assetId, type: assetType });
      setBuyCurrency('USD');
      setValue('buyIsUsd', true);
    }

    if (transactionType === 'sell') {
      setSelectedAsset({ id: assetId, type: assetType });
      setSellCurrency('USD');
      setValue('sellIsUsd', true);

      // update hidden inputs
      setValue('sellAmountAvailableUsd', amountAvailableUsd);
      setValue('sellAmountAvailableAsset', amountAvailableAsset);
    }

    setModalView('startTransaction');
  };

  const handleClickCrypto = (asset: AllAssetIds) => {
    setSearchSelectedAsset({ assetId: asset, assetType: 'crypto' });
  };

  const handleClickSecurity = (asset: AllAssetIds) => {
    setSearchSelectedAsset({ assetId: asset, assetType: 'securities' });
  };

  return (
    <Flex height="58vh">
      <AssetsSearch onClickCryptoAsset={handleClickCrypto} onClickSecurity={handleClickSecurity} />
    </Flex>
  );
}
