import { useQuery } from '@tanstack/react-query';
import { camelizeKeys } from 'humps';

import { useAxiosContext } from '~/hooks';
import { apiRoutes } from '~/routes';
import { AuthResponse } from '~/types';

export const qkAuth = 'qk:auth';

const useGetAuth = ({ authCode }: { authCode: string | null }) => {
  const { apiClient } = useAxiosContext();

  return useQuery({
    queryKey: [qkAuth],
    enabled: !Boolean(authCode),
    queryFn: async () => {
      return apiClient?.get(apiRoutes.auth()).then((r) => {
        return camelizeKeys(r.data) as AuthResponse;
      });
    },
  });
};

export default useGetAuth;
