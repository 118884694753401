/* eslint-disable react/jsx-newline */

import { Box, SkeletonText, Text } from '@chakra-ui/react';
import { dateFormats, formatUsd, uiColors, utcDateToLocal } from '@cryptofi/core-ui';
import Big from 'big.js';

import { AllAssetIds, SipData } from '~/types';
import { logError } from '~/utils';
import isDev from '~/utils/isDev';

const OrderSummary = ({
  isBuy,
  transactionAmount,
  assetId,
  sipData,
  securityPrice,
}: {
  isBuy: boolean;
  transactionAmount: number;
  assetId?: AllAssetIds;
  sipData?: SipData;
  securityPrice?: number;
}) => {
  const { askPrice = 'N/A', bidPrice = 'N/A', askSize, lastSize, bidSize, listingVenue, updatedOn } = sipData || {};

  const { isLocalDev } = isDev();

  if (!sipData || !assetId) {
    return <SkeletonText noOfLines={3} mt={6} />;
  }

  const boxStyles = {
    fontSize: '2xs',
    color: uiColors.sonicSilver(),
    mt: '6',
    sx: {
      p: {
        display: 'inline',
        _after: {
          content: '" "',
        },
      },
    },
  };

  if (sipData && !securityPrice) {
    if (isLocalDev) {
      // eslint-disable-next-line no-console
      console.error(`Missing order summary data: securityPrice`);
    }

    logError({
      error: Error(`Missing order summary data: securityPrice`),
    });

    return (
      <Box {...boxStyles}>
        <Text>Unable to generate order summary.</Text>
      </Box>
    );
  }

  if (sipData && securityPrice) {
    return (
      <Box {...boxStyles}>
        <Text display="inline">
          Order Summary: You are placing a market order to {isBuy ? 'buy' : 'sell'} approximately{' '}
          {formatUsd({ amount: transactionAmount })} of {assetId} based on the current market price of{' '}
          {formatUsd({ amount: securityPrice })}
          {'. '}
          {isBuy && `You will receive approximately ${Big(transactionAmount).div(securityPrice).toFixed(5)} shares. `}
          {!isBuy && `You will sell approximately ${Big(transactionAmount).div(securityPrice).toFixed(5)} shares. `}
          Bid {formatUsd({ amount: bidPrice })} x {bidSize} {listingVenue} • Ask {formatUsd({ amount: askPrice })} x{' '}
          {askSize} {listingVenue} • Last trade {formatUsd({ amount: securityPrice })} x {lastSize} {listingVenue}. NBBO
          last refreshed at {utcDateToLocal({ timeString: updatedOn, dateFormat: dateFormats.shortDateWithTime })}
        </Text>

        <Text display="inline">Market data is provided by Intrinio. This data is delayed by 15 minutes.</Text>

        <Text display="inline">
          Upon confirmation of your order, your order will be processed by CDS1 Securities LLC and executed by RQD*
          Clearing, LLC.
        </Text>
      </Box>
    );
  }
};

export default OrderSummary;
