import { LinkBox, LinkOverlay } from '@chakra-ui/react';
import { CfCard } from '@cryptofi/core-ui';
// eslint-disable-next-line no-restricted-imports
import NextLink from 'next/link';

import { useFeatureSetEnabled } from '~/hooks';
import { uiRoutes } from '~/routes';
import { uiConstants } from '~/theme';

const AccountCards = () => {
  const { isEnabled } = useFeatureSetEnabled();

  // TODO
  // - what is default order when all are enabled?
  // - conditional content for 1) setup incomplete, 2) setup complete no holdings, 3) setup complete with holdings

  return (
    <CfCard flexDir="row">
      {isEnabled(['securities']) && (
        <CfCard
          as={LinkBox}
          borderStyle="dashed"
          transition={`background-color ${uiConstants.transitionDuration}`}
          _hover={{
            background: uiConstants.hoverBackground,
          }}
        >
          <LinkOverlay as={NextLink} href={uiRoutes.selfDirectedSecurities()} fontFamily="heading">
            Self-Directed Securities Account
          </LinkOverlay>
        </CfCard>
      )}

      {isEnabled(['crypto']) && (
        <CfCard
          as={LinkBox}
          transition={`background-color ${uiConstants.transitionDuration}`}
          _hover={{
            background: uiConstants.hoverBackground,
          }}
        >
          <LinkOverlay as={NextLink} href={uiRoutes.selfDirectedCrypto()} fontFamily="heading">
            Crypto Account
          </LinkOverlay>
        </CfCard>
      )}

      {isEnabled(['robo']) && (
        <CfCard
          as={LinkBox}
          transition={`background-color ${uiConstants.transitionDuration}`}
          _hover={{
            background: uiConstants.hoverBackground,
          }}
          borderStyle="dashed"
        >
          <LinkOverlay as={NextLink} href={uiRoutes.guidedInvesting()} fontFamily="heading">
            Guided Investing Account
          </LinkOverlay>
        </CfCard>
      )}
    </CfCard>
  );
};

export default AccountCards;
