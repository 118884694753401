import { Alert, Box, Heading, Show } from '@chakra-ui/react';
import { CfPrettyWrap, IconInfo, uiColors } from '@cryptofi/core-ui';

import { OnboardingStatus, Product } from '~/types';
import { getKycAlertDescription, getKycAlertHeading } from '~/utils';

const KycAlert = ({ product, onboardingStatus }: { product: Product; onboardingStatus?: OnboardingStatus }) => {
  const FormattedDescription = getKycAlertDescription({ onboardingStatus, product });
  return (
    <Alert
      colorScheme={onboardingStatus === 'rejected' ? 'yellow' : 'white'}
      borderColor={onboardingStatus === 'rejected' ? 'yellow.500' : uiColors.coolElegance()}
    >
      <Show above="sm">
        <Box>
          <IconInfo
            mr="4"
            __css={{
              path: { fill: onboardingStatus === 'rejected' ? 'yellow.500' : uiColors.coolElegance() },
            }}
          />
        </Box>
      </Show>

      <CfPrettyWrap lineHeight="normal">
        <Heading as="h3" fontSize="md">
          {getKycAlertHeading({ onboardingStatus, product })}
        </Heading>

        <FormattedDescription />
      </CfPrettyWrap>
    </Alert>
  );
};

export default KycAlert;
