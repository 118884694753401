'use client';

import { Flex, LinkBox, LinkOverlay, Text, VisuallyHidden } from '@chakra-ui/react';
import { CfCard, formatPercentage, formatUsd, uiColors } from '@cryptofi/core-ui';
/* eslint-disable-next-line no-restricted-imports */
import NextLink from 'next/link';

import { AssetLogo, InvestmentStat } from '~/components';
import { uiRoutes } from '~/routes';
import { uiConstants } from '~/theme';
import { Investment } from '~/types';

const InvestmentCard = ({
  investment,
  variant = 'simple',
}: {
  investment: Investment;
  variant?: 'simple' | 'detail';
}) => {
  const { assetId, assetType, currentPrice, amountUsd, avgCost, delta } = investment;
  const isCryptoAsset = assetType === 'crypto';

  return (
    <LinkBox w="full">
      <LinkOverlay
        as={NextLink}
        href={isCryptoAsset ? uiRoutes.assetDetailsCrypto(assetId) : uiRoutes.assetDetailsSecurities(assetId)}
      >
        <CfCard
          gap="3"
          h="full"
          w="full"
          p="3"
          transition={`background-color ${uiConstants.transitionDuration}`}
          _hover={{
            backgroundColor: uiConstants.hoverBackground,
            cursor: 'pointer',
          }}
        >
          <Flex justifyContent="space-between" alignItems="center" gap="3">
            <AssetLogo assetId={assetId} assetType={assetType} showName showId canTruncate />

            <Flex fontFamily="heading" flexDir="column" alignItems="flex-end">
              {currentPrice && (
                <>
                  <VisuallyHidden>Current price:</VisuallyHidden>

                  <Text lineHeight="normal">
                    {formatUsd({
                      amount: currentPrice,
                      autoPrecision: true,
                    })}
                  </Text>
                </>
              )}

              {delta?.daily && (
                <>
                  <VisuallyHidden>Price change:</VisuallyHidden>

                  <Text fontSize="sm" color={delta.daily.isGain ? uiColors.mint() : uiColors.heroicRed()}>
                    {formatPercentage({ number: delta.daily.percentChange || 0 })}
                  </Text>
                </>
              )}
            </Flex>
          </Flex>

          {variant === 'simple' && amountUsd && (
            <Flex
              gap="1"
              alignItems="center"
              justifyContent="center"
              p="2"
              w="full"
              fontSize="sm"
              color={uiColors.sonicSilver()}
              bg={uiColors.lighthouse()}
              borderRadius="md"
            >
              <Text>Holdings</Text>

              <Text fontFamily="heading">{formatUsd({ amount: amountUsd, precision: 0 })}</Text>
            </Flex>
          )}

          {variant === 'detail' && (
            <Flex
              direction={{ base: 'column', md: 'row' }}
              gap={{ base: '2', md: '6' }}
              py="2"
              px="3"
              w="full"
              color={uiColors.sonicSilver()}
              bg={uiColors.lighthouse()}
              borderRadius="md"
            >
              {delta?.daily && amountUsd && (
                <InvestmentStat
                  size="md"
                  label="Current value"
                  showArrow
                  isIncrease={delta.daily.isGain}
                  value={formatUsd({ amount: amountUsd })}
                />
              )}

              {delta?.allTime && (
                <InvestmentStat
                  size="md"
                  label={`Total ${delta.allTime.isGain ? 'gains' : 'losses'}`}
                  showArrow
                  isIncrease={delta.allTime.isGain}
                  value={formatUsd({
                    amount: delta.allTime.totalGainLoss,
                  })}
                />
              )}

              {delta?.allTime && (
                <InvestmentStat
                  size="md"
                  label="Return"
                  showArrow
                  isIncrease={delta.allTime.isGain}
                  value={formatPercentage({ number: delta.allTime.percentChange })}
                />
              )}

              {Boolean(avgCost) && avgCost && (
                <InvestmentStat size="md" label="Average purchase price" value={formatUsd({ amount: avgCost })} />
              )}
            </Flex>
          )}
        </CfCard>
      </LinkOverlay>
    </LinkBox>
  );
};

export default InvestmentCard;
