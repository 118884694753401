import { yupResolver } from '@hookform/resolvers/yup';
import { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import Big from 'big.js';
import { map } from 'lodash';
import { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { useForm } from 'react-hook-form';

import { transactionFormSchema, TransactionFormValues } from '~/components/InvestModal/transactionSchema';
import {
  getAvailableAmount,
  getSellQuantity,
  getTransactionQuantity,
  getTransactionState,
  getWatchedCurrencyAmount,
} from '~/components/InvestModal/utils';
import {
  useGetFiInfo,
  useGetSecurity,
  useGetUser,
  usePostBuyCrypto,
  usePostBuySecurity,
  usePostSellCrypto,
  usePostSellSecurity,
} from '~/hooks';
import {
  AllAssetIds,
  AssetType,
  Order,
  SecurityAsset,
  SelectedAsset,
  TransactionCurrencies,
  TransactionModalViews,
  TransactionTypes,
} from '~/types';

type Context = {
  // shared, buy + sell
  modalView: TransactionModalViews;
  setModalView: Dispatch<SetStateAction<TransactionModalViews>>;
  transactionType: TransactionTypes;
  setTransactionType: Dispatch<SetStateAction<TransactionTypes>>;
  defaultAssetId?: AllAssetIds;
  defaultAssetType?: AssetType;
  selectedAsset?: SelectedAsset; // the asset the user has selected (or default)
  setSelectedAsset: Dispatch<SetStateAction<SelectedAsset | undefined>>;
  selectedSecurity?: UseQueryResult<SecurityAsset>; // selected security detail query
  transactionState?: {
    isPending: boolean;
    isError: boolean;
    isSuccess: boolean;
  };
  formMethods: ReturnType<typeof useForm<TransactionFormValues>>;

  // buy
  buyCurrency: TransactionCurrencies;
  setBuyCurrency: Dispatch<SetStateAction<TransactionCurrencies>>;
  buyPrice: number;
  buyCurrencyAmount: number;
  buyCrypto: UseMutationResult<Order | undefined, unknown, unknown, unknown>; // Buy crypto mutation
  buySecurity: UseMutationResult<Order | undefined, unknown, unknown, unknown>; // Buy security mutation
  buyQuantity: number; // gross amount of crypto the user is purchasing
  netBuyQuantity: number; // net amount of crypto the user will receive after fees
  setNetBuyQuantity: Dispatch<SetStateAction<number>>;
  setBuyPrice: Dispatch<SetStateAction<number>>;

  // sell
  amountAvailableUsd: number; // user's available balance in USD
  amountAvailableAsset: number; // user's available balance in terms of asset UOM (shares or crypto tokens)
  sellCurrency: TransactionCurrencies;
  setSellCurrency: Dispatch<SetStateAction<TransactionCurrencies>>;
  sellPrice: number;
  sellCurrencyAmount: number;
  sellCrypto: UseMutationResult<Order | undefined, unknown, unknown, unknown>; // Sell crypto mutation
  sellSecurity: UseMutationResult<Order | undefined, unknown, unknown, unknown>; // Sell security mutation
  sellQuantity: number;
  sellMaxTransactionLimit: number;
  setSellPrice: Dispatch<SetStateAction<number>>;
};

const InvestModalContext = createContext({} as Context);

const InvestModalProvider = ({
  children,
  defaultAssetId,
  defaultAssetType,
}: {
  children: ReactNode;
  defaultAssetId?: AllAssetIds;
  defaultAssetType?: AssetType;
}) => {
  const [modalView, setModalView] = useState<TransactionModalViews>('startTransaction');
  const [transactionType, setTransactionType] = useState<TransactionTypes>('buy');
  const [selectedAsset, setSelectedAsset] = useState<SelectedAsset | undefined>({
    id: defaultAssetId!,
    type: defaultAssetType!,
  });
  const [buyPrice, setBuyPrice] = useState<number>(0);
  const [sellPrice, setSellPrice] = useState<number>(0);

  const fiInfo = useGetFiInfo();
  const user = useGetUser();
  const isCrypto = selectedAsset?.type === 'crypto';
  const selectedSecurity = useGetSecurity({
    ticker: selectedAsset?.id,
    enabled: !isCrypto,
  });

  const buyTransactionLimits = (isCrypto
    ? fiInfo.data?.transactionLimits.crypto?.buy
    : fiInfo.data?.transactionLimits.security?.buy) ?? { min: '6', max: '5000' };
  const [netBuyQuantity, setNetBuyQuantity] = useState(0);

  const sellTransactionLimits = (isCrypto
    ? fiInfo.data?.transactionLimits.crypto?.sell
    : fiInfo.data?.transactionLimits.security?.sell) ?? { min: '6', max: '5000' };

  const formMethods = useForm<TransactionFormValues>({
    resolver: yupResolver(transactionFormSchema({ buyPrice, sellPrice, buyTransactionLimits, sellTransactionLimits })),
    mode: 'onChange',
  });

  // buy form
  const [buyCurrency, setBuyCurrency] = useState<TransactionCurrencies>('USD');
  const buyCurrencyAmount = getWatchedCurrencyAmount({
    watch: formMethods.watch,
    currency: buyCurrency,
    name: 'buyCurrencyAmount',
  });
  const buyCrypto = usePostBuyCrypto() as UseMutationResult<Order | undefined, unknown, unknown, unknown>;
  const buySecurity = usePostBuySecurity() as UseMutationResult<Order | undefined, unknown, unknown, unknown>;

  const buyQuantity = getTransactionQuantity({
    currency: buyCurrency,
    currencyAmount: buyCurrencyAmount,
    price: buyPrice,
  });

  // sell form
  const [sellCurrency, setSellCurrency] = useState<TransactionCurrencies>('USD');
  const sellCurrencyAmount = getWatchedCurrencyAmount({
    watch: formMethods.watch,
    currency: sellCurrency,
    name: 'sellCurrencyAmount',
  });
  const sellCrypto = usePostSellCrypto() as UseMutationResult<Order | undefined, unknown, unknown, unknown>;
  const sellSecurity = usePostSellSecurity() as UseMutationResult<Order | undefined, unknown, unknown, unknown>;

  const cryptoBalances = map(user.data?.cryptoSelfDirectedAccount.balance, (value, assetId) => ({
    assetId,
    amountAvailable: Number(value.amountAvailable),
  }));

  const securityBalances = map(user.data?.securitiesSelfDirectedAccount.balance, (value, assetId) => ({
    assetId,
    amountAvailable: Number(value.amountAvailable),
  }));
  const { amountAvailableUsd, amountAvailableAsset } = getAvailableAmount({
    securityBalances,
    cryptoBalances,
    assetId: selectedAsset?.id!,
    sellPrice,
  });

  const isMaxSell =
    amountAvailableUsd !== 0 &&
    Number(formMethods.getValues('sellCurrencyAmount') || 0) === Big(amountAvailableUsd).round(2).toNumber();

  const sellQuantity = getSellQuantity({
    isMaxSell,
    amountAvailableAsset,
    amountAvailableUsd,
    sellCurrency,
    sellCurrencyAmount,
    sellPrice,
    isAssetCrypto: isCrypto,
  });

  // lookup state of the transaction mutation
  const { transactionState } = getTransactionState({
    assetType: selectedAsset?.type!,
    transactionType,
    buyCrypto,
    sellCrypto,
    buySecurity,
    sellSecurity,
  });

  return (
    <InvestModalContext.Provider
      value={{
        // shared, buy + sell
        modalView,
        setModalView,
        transactionType,
        setTransactionType,
        defaultAssetId,
        defaultAssetType,
        selectedAsset,
        setSelectedAsset,
        selectedSecurity,
        transactionState,
        formMethods,

        // buy
        buyCurrency,
        setBuyCurrency,
        buyPrice,
        buyCurrencyAmount,
        buyCrypto,
        buySecurity,
        setBuyPrice,
        buyQuantity,
        netBuyQuantity,
        setNetBuyQuantity,

        // sell
        sellCurrency,
        setSellCurrency,
        sellPrice,
        sellCurrencyAmount,
        sellCrypto,
        sellSecurity,
        setSellPrice,
        sellQuantity,
        sellMaxTransactionLimit: Number(sellTransactionLimits.max),
        amountAvailableUsd,
        amountAvailableAsset,
      }}
    >
      {children}
    </InvestModalContext.Provider>
  );
};

export { InvestModalContext, InvestModalProvider };
