// eslint-disable-next-line no-restricted-imports
import { Button, Flex, Heading, Link, List, ListItem, Skeleton, Text } from '@chakra-ui/react';
import { CfCard, CfSpinner, uiColors } from '@cryptofi/core-ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { debounce, startCase } from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { useFeatureSetEnabled, useGetFiInfo, useGetUser, usePostTelemetryEvent, usePostTerms } from '~/hooks';
import { Product, TelemetryEvents, telemetryEvents } from '~/types';
import { hasAcceptedTermsForFeature } from '~/utils';

import TermsCheckbox from './TermsCheckbox';

const arbitrationClauseDescription =
  'Arbitration can have a substantial impact on your rights, including your right to a jury trial and your right to pursue or participate in a class action lawsuit.';

const termsAndConditionsFormSchema = Yup.object().shape({
  advisifiBrokerageLicense: Yup.boolean().oneOf([true]),
  advisifiBrokerageLicenseArbitrationClause: Yup.boolean().oneOf([true]),
  investifi: Yup.boolean().oneOf([true]),
  investifiArbitrationClause: Yup.boolean().oneOf([true]),
  investifiBrokerageLicense: Yup.boolean().oneOf([true]),
  investifiBrokerageLicenseArbitrationClause: Yup.boolean().oneOf([true]),
  crypto: Yup.boolean().oneOf([true]),
  securities: Yup.boolean().oneOf([true]),
});
const TermsAndConditionsView = ({
  hasNewTerms,
  onClose,
  onboardingProducts,
  setOnboardingProducts,
}: {
  hasNewTerms?: boolean;
  onClose: () => void;
  onboardingProducts: Product[];
  setOnboardingProducts: (products: Product[]) => void;
}) => {
  const { isEnabled } = useFeatureSetEnabled();
  const user = useGetUser();
  const fiInfo = useGetFiInfo();
  const postTerms = usePostTerms({
    onboardingProducts,
    setOnboardingProducts,
  });

  const { trackEvent } = usePostTelemetryEvent();

  // close the modal when the user has accepted updated terms
  useEffect(() => {
    if (hasNewTerms && postTerms.isSuccess) {
      onClose();
    }
  }, [hasNewTerms, onClose, postTerms.isSuccess]);

  const { handleSubmit, register, getValues, formState } = useForm({
    resolver: yupResolver(termsAndConditionsFormSchema),
    mode: 'onChange',
  });

  // Handle various click events in the form to send them to our telemetry service
  // debounce is needed to keep the event from firing twice onClick
  const handleTermsClickEvent = debounce((eventType: TelemetryEvents) => {
    trackEvent(eventType);
  }, 500);

  const onSubmit = () => {
    postTerms.mutate();
  };

  if (!user?.data) {
    return <Skeleton height="20rem" mb={8} />;
  }

  const isSubmitting = formState.isSubmitted && (postTerms.isPending || postTerms.isSuccess || user.isRefetching);

  return (
    <Flex flexDir="column" w="full" mb="12">
      {!isSubmitting && (
        <Flex flexDir="column" w="full" gap="4">
          {fiInfo.data?.termsAndConditions && (
            <>
              <Text color={uiColors.sonicSilver()} fontSize="sm">
                {hasNewTerms
                  ? `We've updated our terms! As part of a periodic review, ${fiInfo.data?.fiName} has updated its user terms. Please accept the latest version to continue investing.`
                  : 'To get started, please review and accept the Terms and Conditions.'}
              </Text>

              {!user.data?.termsAndConditions?.investifi?.dateAccepted && (
                <CfCard background={uiColors.lighthouse()}>
                  <TermsCheckbox
                    termsParty="InvestiFi"
                    register={register}
                    name="investifi"
                    onClick={() => {
                      // the form value will not have been updated prior to this firing thus we check what the previous state was
                      const wasPreviouslyChecked = getValues('investifi');
                      if (!wasPreviouslyChecked) {
                        handleTermsClickEvent(telemetryEvents.TCModalClickedAgreeToTermsAndConditionsCheckboxClient);
                      }
                    }}
                    description={fiInfo.data.termsAndConditions.investifi?.description}
                    policies={fiInfo.data.termsAndConditions.investifi?.policies}
                  />

                  <TermsCheckbox
                    termsParty="InvestiFi"
                    register={register}
                    name="investifiArbitrationClause"
                    description={arbitrationClauseDescription}
                    isArbitrationClause={true}
                    policies={[fiInfo.data.termsAndConditions.investifi?.arbitrationClause]}
                  />
                </CfCard>
              )}

              {isEnabled(['crypto']) &&
                onboardingProducts.includes('crypto') &&
                !hasAcceptedTermsForFeature({ user: user.data, featureSet: 'crypto' }) && (
                  <>
                    <Flex gap="2" alignItems="center">
                      <Heading as="h3" size="xs" color={uiColors.sonicSilver()}>
                        Cryptocurrencies
                      </Heading>

                      <Text fontSize="2xs" color={uiColors.sonicSilver()}>
                        Offered by SAFE Trust Co.
                      </Text>
                    </Flex>

                    <CfCard background={uiColors.lighthouse()}>
                      <TermsCheckbox
                        termsParty={startCase(fiInfo.data.fiCustodianName || 'crypto provider')}
                        register={register}
                        name="crypto"
                        onClick={() => {
                          // the form value will not have been updated prior to this firing thus we check what the previous state was
                          const wasPreviouslyChecked = getValues('crypto');
                          if (!wasPreviouslyChecked) {
                            handleTermsClickEvent(
                              telemetryEvents.TCModalClickedAgreeToCustodianTermsAndConditionsCheckboxClient,
                            );
                          }
                        }}
                        description={fiInfo.data.termsAndConditions.crypto?.description}
                        policies={fiInfo.data.termsAndConditions.crypto?.policies}
                      />

                      <Flex fontSize="2xs" mt="2" flexDir="column" gap="2">
                        {/* eslint-disable react/jsx-newline */}
                        {/* only include CDS1 text for combined experience */}
                        <Text>
                          By submitting this form, you understand and acknowledge that AdvisiFi LLC{' '}
                          {isEnabled(['securities', 'crypto']) ? 'and CDS1 Securities LLC do' : 'does'} not offer or
                          provide recommendations or advice regarding cryptocurrencies, and that your cryptocurrency
                          account is held with, and all cryptocurrency trading is offered and facilitated by SAFE Trust
                          Co.
                        </Text>
                        {/* eslint-enable react/jsx-newline */}
                      </Flex>
                    </CfCard>
                  </>
                )}

              {isEnabled(['securities']) &&
                onboardingProducts.includes('securities') &&
                !hasAcceptedTermsForFeature({ user: user.data, featureSet: 'securities' }) && (
                  <>
                    <Flex gap="2" alignItems="center">
                      <Heading as="h3" size="xs" color={uiColors.sonicSilver()}>
                        Self-directed securities (stocks)
                      </Heading>

                      <Text fontSize="2xs" color={uiColors.sonicSilver()}>
                        Offered by CDS1 Securities LLC
                      </Text>
                    </Flex>

                    <CfCard background={uiColors.lighthouse()}>
                      {!user.data?.termsAndConditions?.securities?.dateAccepted && (
                        <TermsCheckbox
                          termsParty={startCase(fiInfo.data.fiSecuritiesProviderName || 'securities provider')}
                          register={register}
                          name="securities"
                          description={fiInfo.data.termsAndConditions.securities?.description}
                          policies={fiInfo.data.termsAndConditions.securities?.policies}
                        />
                      )}

                      {!user.data?.termsAndConditions?.investifiBrokerageLicense?.dateAccepted && (
                        <>
                          <TermsCheckbox
                            termsParty="CDS1"
                            register={register}
                            name="investifiBrokerageLicense"
                            description={fiInfo.data.termsAndConditions.investifiBrokerageLicense?.description}
                            policies={fiInfo.data.termsAndConditions.investifiBrokerageLicense?.policies}
                          />

                          <TermsCheckbox
                            termsParty="CDS1"
                            register={register}
                            name="investifiBrokerageLicenseeArbitrationClause"
                            isArbitrationClause={true}
                            description={arbitrationClauseDescription}
                            policies={[fiInfo.data.termsAndConditions.investifiBrokerageLicense?.arbitrationClause]}
                          />
                        </>
                      )}

                      {!user.data?.termsAndConditions?.advisifiBrokerageLicense?.dateAccepted && (
                        <>
                          <TermsCheckbox
                            termsParty="AdvisiFi"
                            register={register}
                            name="advisifiBrokerageLicense"
                            description={fiInfo.data.termsAndConditions.advisifiBrokerageLicense?.description}
                            policies={fiInfo.data.termsAndConditions.advisifiBrokerageLicense?.policies}
                          />

                          <TermsCheckbox
                            termsParty="AdvisiFi"
                            register={register}
                            name="advisifiBrokerageLicenseArbitrationClause"
                            isArbitrationClause={true}
                            description={arbitrationClauseDescription}
                            policies={[fiInfo.data.termsAndConditions.advisifiBrokerageLicense?.arbitrationClause]}
                          />
                        </>
                      )}

                      <Flex fontSize="2xs" mt="2" flexDir="column" gap="2">
                        <Text>
                          By submitting this form, you acknowledge electronic receipt of the following documents:
                        </Text>

                        <List ml="4" styleType="disc">
                          <ListItem>
                            <Link
                              href="https://investifi.co/cds1-securities-form-crs-self-elected/"
                              target="cds1-crs"
                              textDecor="underline"
                            >
                              CDS1 Form CRS
                            </Link>
                          </ListItem>

                          <ListItem>
                            <Link
                              href="https://investifi.co/cds1-securities-privacy-notice/"
                              target="cds1-privacy"
                              textDecor="underline"
                            >
                              CDS1 Privacy Notice
                            </Link>
                          </ListItem>

                          <ListItem>
                            <Link
                              href="https://www.finra.org/investors/customer-identification-program-notice"
                              target="cds1-customer-id"
                              textDecor="underline"
                            >
                              CDS1 Customer Identification Program Notice
                            </Link>
                          </ListItem>

                          <ListItem>
                            {/* TODO get correct URL from product, does this duplicate top-level checkbox? */}
                            <Link
                              href="https://investifi.co/legal-disclosures"
                              target="rqd-privacy"
                              textDecor="underline"
                            >
                              RQD Privacy Notice
                            </Link>
                          </ListItem>

                          <ListItem>
                            <Link
                              href="https://investifi.co/advisifi-crs-final/"
                              target="advisifi-crs"
                              textDecor="underline"
                            >
                              AdvisiFi Form CRS
                            </Link>
                          </ListItem>

                          <ListItem>
                            <Link
                              href="https://investifi.co/form-adv-part-2a-advisifi/"
                              target="advisifi-adv"
                              textDecor="underline"
                            >
                              AdvisiFi Form ADV Part 2A Brochure
                            </Link>
                          </ListItem>
                        </List>
                      </Flex>
                    </CfCard>
                  </>
                )}

              <Text fontSize="2xs" color={uiColors.sonicSilver()}>
                Your Financial Institution may have its own terms of use or similar agreement that you agree to by
                accessing the Services via their website or application. Your use of the Services constitutes your
                agreement to the same.
              </Text>
            </>
          )}

          <Flex width="full">
            <Button
              isDisabled={!formState.isValid}
              onClick={handleSubmit(onSubmit)}
              isLoading={isSubmitting}
              width={{ base: 'full', md: 'auto' }}
              ml="auto"
            >
              Agree and continue
            </Button>
          </Flex>
        </Flex>
      )}

      {isSubmitting && (
        <Flex flexDir="column" gap="4" w="full" alignItems="center">
          <CfSpinner />
        </Flex>
      )}
    </Flex>
  );
};

export default TermsAndConditionsView;
