import * as Yup from 'yup';

export const roboTransactionFormSchema = () => {
  const errors = {
    accountRequired: 'Bank account is required',
    positiveAmount: 'Amount must be greater than 0',
    insufficientDepositBalance: 'Please select an account with sufficient funds',
    insufficientWithdrawalBalance: 'Please select an amount equal to or less than your account balance',
  };

  const depositFields = {
    depositAvailableBalance: Yup.number(),

    depositAmount: Yup.string()
      .required('Deposit amount is required') // no error message here because the button gets disabled if empty
      .test('positiveAmount', errors.positiveAmount, (value) => {
        if (typeof value === 'string') {
          return Number(value) > 0;
        }

        return false;
      }),

    depositAccountId: Yup.string()
      .required(errors.accountRequired)
      .test('balanceCheck', errors.insufficientDepositBalance, (_, context) => {
        const { depositAvailableBalance, depositAmount } = context.parent;

        if (depositAmount) {
          return depositAvailableBalance >= Number(depositAmount);
        }

        return true;
      }),
  };

  const withdrawalFields = {
    withdrawalAvailableBalance: Yup.number(),

    withdrawalAmount: Yup.string()
      .required('Withdrawal amount is required') // no error message here because the button gets disabled if empty
      .test('positiveAmount', errors.positiveAmount, (value) => {
        if (typeof value === 'string') {
          return Number(value) > 0;
        }

        return false;
      })
      .test('balanceCheck', errors.insufficientWithdrawalBalance, (_, context) => {
        const { withdrawalAvailableBalance, withdrawalAmount } = context.parent;

        if (withdrawalAmount) {
          return withdrawalAvailableBalance >= Number(withdrawalAmount);
        }

        return true;
      }),

    withdrawalAccountId: Yup.string().required(errors.accountRequired),
  };

  return Yup.object().shape({
    transactionType: Yup.string().required().oneOf(['deposit', 'withdrawal']),
    ...depositFields,
    ...withdrawalFields,
  });
};

export type RoboTransactionFormValues = Yup.InferType<ReturnType<typeof roboTransactionFormSchema>>;
