import { Button, ButtonGroup, ButtonGroupProps } from '@chakra-ui/react';
import { uiColors } from '@cryptofi/core-ui';

import { useInvestModalContext } from '~/hooks';
import { uiConstants } from '~/theme';
import { RoboTransactionTypes, TransactionTypes } from '~/types';
interface Props extends ButtonGroupProps {
  transactionType: TransactionTypes | RoboTransactionTypes;
  maxAmount?: number;
  onMaxSelect?: () => void;
}

const PresetAmounts = ({ transactionType, maxAmount = 0, onMaxSelect, ...rest }: Props) => {
  const buttonProps = {
    bg: uiColors.lighthouse(),
    color: 'inherit',
    boxShadow: 'none',
    borderRadius: '2xl',
    border: 'solid 2px',
    borderColor: uiColors.lighthouse(),
    transition: `background ${uiConstants.transitionDuration}`,
    _hover: { bg: 'transparent' },
    _focus: { bg: 'transparent' },
    minWidth: '16',
    px: '4',
  };
  const { sellMaxTransactionLimit } = useInvestModalContext();

  const isIncreasingBalance = transactionType === 'buy' || transactionType === 'deposit';

  const amounts = isIncreasingBalance ? [100, 200, 500] : [100, 200, 'max'];

  const max =
    transactionType === 'sell' ? Math.min(maxAmount, sellMaxTransactionLimit).toFixed(2) : maxAmount.toFixed(2);

  return (
    <ButtonGroup size="sm" justifyContent="center" gap="2" {...rest}>
      {amounts.map((amount) => {
        return (
          <Button
            value={amount === 'max' ? max : amount}
            key={amount}
            onClick={amount === 'max' && onMaxSelect ? onMaxSelect : undefined}
            {...buttonProps}
          >
            {amount === 'max' ? 'Max' : `$${amount}`}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

export default PresetAmounts;
