'use client';

import { IconButton, TableRowProps, Td, Tooltip, Tr } from '@chakra-ui/react';
import { IconDownload, splitOnCapitals } from '@cryptofi/core-ui';
import { capitalize } from 'lodash';
import { useEffect } from 'react';

import { useFeatureSetEnabled, useGetUserReport } from '~/hooks';
import { UserReport } from '~/types';

interface Props extends TableRowProps {
  report: UserReport;
}

const ReportRow = ({ report, ...rest }: Props) => {
  const {
    data: reportUrl,
    isFetching,
    refetch,
  } = useGetUserReport({
    reportId: report.sKey || '',
    gcTime: 100, // unused/inactive cache data remains in memory for 100ms
  });

  const { isEnabled } = useFeatureSetEnabled();

  useEffect(() => {
    if (reportUrl) {
      // use sKey as the window target to prevent multiple tabs with the same file
      window.open(reportUrl, report.sKey || '');
    }
  }, [report, report.sKey, reportUrl]);

  return (
    <Tr {...rest}>
      <Td>{splitOnCapitals(report.reportType)}</Td>

      <Td>{report.reportDate}</Td>

      {isEnabled(['securities', 'crypto']) && <Td>{capitalize(report.reportProductType)}</Td>}

      <Td display="flex" justifyContent="flex-end">
        <Tooltip label="Download report" aria-label="Download report" hasArrow>
          <IconButton aria-label="Download report" isLoading={isFetching} variant="outline" onClick={() => refetch()}>
            <IconDownload />
          </IconButton>
        </Tooltip>
      </Td>
    </Tr>
  );
};

export default ReportRow;
